import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../../../data/services/auth/auth.service';
import { RouterGuardsViewModel } from '../../models/RouterGuards.view-model';
import { Observable } from 'rxjs';
import {
  bitapEither,
  mapEitherToValue,
  tapEitherAnyway,
} from '@ui/jug-ui';
import { NavigationService } from '../../../routing/navigation.service';
import { REFERRAL_URL_PARAM_NAME, ROUTES_PATHS } from '../../../routing/navigation.consts';
import { CurrentUserModel } from '../../../data/models/user/current-user.model';


@Injectable()
export class LoggedInGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private routerGuardsViewModel: RouterGuardsViewModel,
    private navigationService: NavigationService,
    private currentUserModel: CurrentUserModel,
  ) {}


  private redirectToAuthorize(state: RouterStateSnapshot) {
    let resultOpeningContextUrl = state.url;
    let referralValue;

    if (state.root.queryParams.hasOwnProperty(REFERRAL_URL_PARAM_NAME)) {
      const urlModificationResult = this.navigationService.modifyUrl(
        state.url,
        {
          extract: {
            queryParams: [REFERRAL_URL_PARAM_NAME],
          },
          remove: {
            queryParams: [REFERRAL_URL_PARAM_NAME],
          },
        }
      );
      referralValue = urlModificationResult.extracted.queryParams[REFERRAL_URL_PARAM_NAME];
      resultOpeningContextUrl = urlModificationResult.url.toString();
    }

    return this.navigationService.navigateToAuthorize(
      true,
      {
        openingContextUrl: resultOpeningContextUrl,
        referral: referralValue,
      }
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.routerGuardsViewModel.loggedInPartIsChecking.set(true);

    return this.authService
      .checkAuth()
      .pipe(
        tapEitherAnyway(() => {
          this.routerGuardsViewModel.loggedInPartIsChecking.set(false);
        }),
        bitapEither(
          isLoggedIn => {
            if (this.currentUserModel.hasConfirmationRequest) {
              if (!state.url.includes(ROUTES_PATHS.mergeLk[0])) {
                return this.navigationService.navigateToMergeLk();
              }
            }

            if (!isLoggedIn) {
              this.redirectToAuthorize(state);
            }
          },
          () => {
            this.redirectToAuthorize(state);
          }
        ),
        mapEitherToValue(false),
      );
  }

}
