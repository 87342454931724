
/**
 * @see https://wiki.jugru.org/pages/viewpage.action?pageId=297500690
 */
export enum UserRole {
  REGULAR = 'regular',

  COMPANY_REGULAR = 'company_regular',
  COMPANY_ADMIN = 'company_admin',

  ONLINE_ADMIN = 'online_admin',
  ONLINE_EDITOR = 'online_editor',

  ADMIN = 'admin',

  SRM_USER = 'srm_user',
  SRM_COORDINATOR = 'srm_coordinator',
  SRM_EDITOR = 'srm_editor',

  // not supported roles
  SPEAKER = 'speaker',
  PROGRAM_COMMITTEE = 'program_committee',
}

