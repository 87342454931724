export const EMPTY_DISPLAY_NAME = '';

export const createDisplayName = (nickName?: string, firstName?: string, familyName?: string, email?: string): string => {
  if (!nickName && !firstName && !familyName && !email) {
    return EMPTY_DISPLAY_NAME;
  }

  if (!firstName && !familyName && nickName) {
    return nickName;
  }

  if (!nickName) {
    return email;
  }

  return `${firstName} ${familyName}`;
}
