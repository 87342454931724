import { UserRole } from './UserRole';
import { UserPermissionPermissionCheckingOptions } from '../../services/permission/permission.service';


export const BASE_COMPANY_PART_PAGE_PERMISSIONS = [
  UserRole.COMPANY_REGULAR,
  UserRole.COMPANY_ADMIN,
];

export const COMPANY_PROFILE_PAGE_PERMISSIONS = [
  UserRole.COMPANY_REGULAR,
  UserRole.COMPANY_ADMIN,
];

export const COMPANY_ORDERS_PAGE_PERMISSIONS = [
  UserRole.COMPANY_REGULAR,
  UserRole.COMPANY_ADMIN,
];

export const BASE_COMPANY_PART_PERMISSIONS_OPTIONS: UserPermissionPermissionCheckingOptions = {
  isForCompanies: true,
  isAny: true,
};

export const BASE_ADMINISTRATION_PART_PERMISSIONS = [
  UserRole.ADMIN,
];

