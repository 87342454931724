
<ng-container *ngIf="(menu$ | async) as menu">

  <a
    class="auth-menu-item"

    *ngFor="let menuItem of menu.items"
    [routerLink]="menuItem.link.link"
    [queryParams]="menuItem.link.queryParams"

    [jcActiveRouteChecker]="menuItem.link"
    [activeRouteCheckingOptions]="menuItem.routeOptions"
    [activeRouteExtraLink]="menuItem.extraActiveLink"
    activeRouteClass="active"
  >
    {{ 'authMenu.items.' + menuItem.key | translate }}
  </a>

</ng-container>
