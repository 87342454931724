/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ui/jug-ui/ui-jug-ui.ngfactory";
import * as i3 from "@ui/jug-ui";
import * as i4 from "./ui/components/user-profile-role-representation/user-profile-role-representation.component.ngfactory";
import * as i5 from "./ui/components/user-profile-role-representation/user-profile-role-representation.component";
import * as i6 from "@angular/common";
import * as i7 from "./ui/common/header/header.component.ngfactory";
import * as i8 from "./ui/common/header/header.component";
import * as i9 from "./data/models/user/current-user.model";
import * as i10 from "./data/services/auth/auth.service";
import * as i11 from "./routing/navigation.service";
import * as i12 from "@angular/router";
import * as i13 from "./data/services/configuration/configuration.service";
import * as i14 from "./ui/common/footer/footer.component.ngfactory";
import * as i15 from "./ui/common/footer/footer.component";
import * as i16 from "./app.component";
import * as i17 from "./ui/models/RouterGuards.view-model";
import * as i18 from "./data/services/referal/referral.service";
import * as i19 from "./data/services/notifications/notifications.service";
import * as i20 from "./data/services/analytics/AnalyticsClientProvider";
import * as i21 from "./data/services/user/profile/user-profile.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "jc-loader-container", [], null, null, null, i2.View_LoaderContainerComponent_0, i2.RenderType_LoaderContainerComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoaderContainerComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "jc-default-loader", [], null, null, null, i2.View_DefaultLoaderComponent_0, i2.RenderType_DefaultLoaderComponent)), i1.ɵdid(3, 49152, null, 0, i3.DefaultLoaderComponent, [], null, null)], null, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-profile-role-representation", [], null, null, null, i4.View_UserProfileRoleRepresentationComponent_0, i4.RenderType_UserProfileRoleRepresentationComponent)), i1.ɵdid(1, 114688, null, 0, i5.UserProfileRoleRepresentationComponent, [], { user: [0, "user"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canShowUserProfileRoleRepresentation(_v.context.ngIf); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], [[2, "__has-second-level-menu", null], [2, "__has-mobile-navigation", null], [2, "__has-mobile-second-level-navigation", null]], null, null, i7.View_HeaderComponent_0, i7.RenderType_HeaderComponent)), i1.ɵdid(1, 49152, null, 0, i8.HeaderComponent, [i9.CurrentUserModel, i10.AuthService, i11.NavigationService, i12.Router, i3.GlobalTouchListeners, i13.ConfigurationService], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_3)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i14.View_FooterComponent_0, i14.RenderType_FooterComponent)), i1.ɵdid(9, 49152, null, 0, i15.FooterComponent, [i13.ConfigurationService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.userProfileService.currentUserModel.user.stream$)); _ck(_v, 3, 0, currVal_3); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hasSecondLevelMenu; var currVal_1 = i1.ɵnov(_v, 1).hasMobileNavigation; var currVal_2 = i1.ɵnov(_v, 1).hasMobileSecondLevelNavigation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["contentBlock", 2]], null, 0, null, View_AppComponent_2)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "jc-modal-popup-overlay", [], [[1, "active", 0]], null, null, i2.View_ModalPopupOverlayComponent_0, i2.RenderType_ModalPopupOverlayComponent)), i1.ɵdid(5, 49152, null, 0, i3.ModalPopupOverlayComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.isPageLoading$)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 5).isModalOpen; _ck(_v, 4, 0, currVal_2); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i16.AppComponent, [i3.I18nService, i12.Router, i17.RouterGuardsViewModel, i11.NavigationService, i18.ReferralService, i19.NotificationsService, i20.AnalyticsClientProvider, i10.AuthService, i21.UserProfileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i16.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
