import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CurrentUserDataModel } from '../../../data/models/user/current-user.data-model';
import { UserRole } from '../../../data/models/user/UserRole';
import {
  getUserProfileRoleRepresentationFromLocaleStorage,
  saveUserProfileRoleRepresentationToLocaleStorage
} from './user-profile-role-representation.localeSrorage';


@Component({
  selector: 'app-user-profile-role-representation',
  templateUrl: './user-profile-role-representation.component.html',
  styleUrls: ['./user-profile-role-representation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileRoleRepresentationComponent implements OnInit {
  @Input() user: CurrentUserDataModel;

  public detailsOpened = false;
  public selectedRole: UserRole;

  constructor(
  ) {
    const role = getUserProfileRoleRepresentationFromLocaleStorage();

    this.selectedRole = role;
  }

  ngOnInit() {
  }

  public onRoleChecked(role: UserRole) {
    this.selectedRole = role;

    saveUserProfileRoleRepresentationToLocaleStorage(role);
  }

  onToggleDetails(details: HTMLDetailsElement) {
    const hasOpenAttr = details.hasAttribute('open');

    this.detailsOpened = hasOpenAttr;
  }

  public filterRoles(roles: UserRole[]): UserRole[] {
    return roles.filter(r => (
      r !== UserRole.ONLINE_ADMIN &&
      r !== UserRole.SRM_USER &&
      r !== UserRole.ONLINE_EDITOR &&
      r !== UserRole.SRM_COORDINATOR  &&
      r !== UserRole.SRM_EDITOR
    ));
  }
}
