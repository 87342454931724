<div
  class="{{ 'content ' + (detailsOpened ? '' : 'role_' + selectedRole) }}"
>
  <details
    #details
    (toggle)="onToggleDetails(details)"
  >
    <summary>
      <h3>
        <ng-container *ngIf="!selectedRole">
          {{ 'user-profile-role.default-main-page.title-no' | translate }}
        </ng-container>

        <ng-container *ngIf="selectedRole">
          {{ 'user-profile-role.default-main-page.title-yes' | translate }}
          &#8211;
          {{ ('user-profile-role.default-main-page.' + selectedRole) | translate }}
        </ng-container>
      </h3>

    </summary>

    <div class="roles">
      <ng-container *ngFor="let role of filterRoles(user.roles)">
        <div class="role-check">
          <jc-input-checkbox
            inputId="{{ 'check_' + role }}"
            [isChecked]="selectedRole === role"
            [disabled]="selectedRole === role"
            (onValueChange)="onRoleChecked(role)"
          >
          </jc-input-checkbox>
          <label
            [htmlFor]="'check_' + role"
            class="{{ 'role ' + 'role_' + role }}"
          >
            <span>
              {{ ("user-profile-role.default-main-page." + role) | translate }}
            </span>
          </label>
        </div>
      </ng-container>
    </div>

  </details>
</div>
