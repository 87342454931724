import { runBootstrap, BootstrappingResult, SaveAnalyticsService } from '@analytics-ring/analytics-ring-collector-client';
import { environment } from '../../../../environments/environment';


export class AnalyticsClientProvider {

  private analyticsRingClientServices: BootstrappingResult;

  constructor() {
    this.analyticsRingClientServices = runBootstrap({
      collectorServiceUrl: environment.analyticRing.serviceUrl,
    });
  }

  getSaveAnalyticsService(): SaveAnalyticsService {
    return this.analyticsRingClientServices.services.saveAnalytics;
  }

}
