import { EnvironmentConfigurationService, ENVIRONMENT_CONFIGURATION_SERVICE_INJECTION_TOKEN } from '@ui/jug-ui';
import { environment } from '../../../../environments/environment';


export class CurrentEnvironmentConfigurationService implements EnvironmentConfigurationService {

  isProdModeEnabled(): boolean {
    return environment.production;
  }

  isIntegrationTestingEnabled(): boolean {
    return environment.integrationTesting.isEnabled;
  }

}


export const currentEnvironmentConfigurationServiceProvider = {
  provide: ENVIRONMENT_CONFIGURATION_SERVICE_INJECTION_TOKEN,
  useClass: CurrentEnvironmentConfigurationService,
  multi: false,
};
