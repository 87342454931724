import { ProtectedSubjectWrapper } from '@ui/jug-ui';
import { UnionDataLayerErrors } from '../../services/DataLayerErrorCode';
import { FetchingDataStateModelForRxWithFlags } from '@ui/fetching-data-state';


export class UserProfileModel {

  profileUpdateDataState = new FetchingDataStateModelForRxWithFlags();
  profileUpdateErrors = new ProtectedSubjectWrapper<UnionDataLayerErrors>(null);

  changePasswordDataState = new FetchingDataStateModelForRxWithFlags();
  changePasswordSettingErrors = new ProtectedSubjectWrapper<UnionDataLayerErrors>(null);

}
