<jc-loader-container
  *ngIf="isPageLoading$ | async; else contentBlock"
>
  <jc-default-loader></jc-default-loader>
</jc-loader-container>

<ng-template #contentBlock>
  <app-header></app-header>

  <ng-container *ngIf="(userProfileService.currentUserModel.user.stream$ | async) as user">
    <app-user-profile-role-representation
      *ngIf="canShowUserProfileRoleRepresentation(user)"
      [user]="user"
    ></app-user-profile-role-representation>
  </ng-container>

  <main>
    <router-outlet></router-outlet>
  </main>
  <app-footer></app-footer>
</ng-template>

<jc-modal-popup-overlay></jc-modal-popup-overlay>
