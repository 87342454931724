import { generateUniqClientSideId } from '@ui/jug-ui';
import { NotificationViewType } from './NotificationViewType';
import { NotificationType } from './NotificationType';


export type NotificationDataModelId = string;


export class NotificationDataModel {
  constructor(
    public id: NotificationDataModelId,
    public creationTime: number,
    public durationOfShowing: number,
    public type: NotificationType,
    public viewType: NotificationViewType,
  ) {}
}


export function createNotificationDataModel(
  creationTime: number,
  durationOfShowing: number,
  type: NotificationType,
  viewType: NotificationViewType = NotificationViewType.DEFAULT,
) {
  return new NotificationDataModel(
    generateUniqClientSideId('notification'),
    creationTime,
    durationOfShowing,
    type,
    viewType,
  );
}

