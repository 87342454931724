import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Either,
  createCommonErrors,
  mapEither,
  ofLeftEither,
} from '@ui/jug-ui';
import { DataLayerErrorCode, UnionDataLayerErrors } from '../DataLayerErrorCode';
import { ApiService } from '../api/api.service';
import { APICommandName } from '../api/commands/APICommandName';
import { eitherifyAPIServiceResponse } from '../api/eitherifyAPIServiceResponse';


@Injectable()
export class ReferralService {

  constructor(
    private apiService: ApiService,
  ) {}


  private savedReferral: string = null;
  setReferral(savedReferral: string) {
    this.savedReferral = savedReferral;
  }
  getReferral() {
    return this.savedReferral;
  }
  resetReferral() {
    this.savedReferral = null;
  }


  tryToSendReferral(): Observable<Either<boolean, UnionDataLayerErrors>> {
    const savedReferral = this.savedReferral;

    if (!savedReferral) {
      return ofLeftEither(createCommonErrors(DataLayerErrorCode.REFERRAL_IS_NOT_PROVIDED));
    }

    return this.apiService
      .request(
        APICommandName.ADD_REFERRAL_ADD,
        {
          referral: savedReferral,
        }
      )
      .pipe(
        eitherifyAPIServiceResponse(),
        mapEither(true)
      );
  }


}
