import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuData } from '../MenuData';
import { AuthMenuViewModel } from '../../../../models/AuthMenu.view-model';


@Component({
  selector: 'app-auth-menu',
  templateUrl: './auth-menu.component.html',
  styleUrls: ['./auth-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthMenuComponent {

  menu$ = new BehaviorSubject<MenuData>(null);

  constructor(
    private authMenuViewModel: AuthMenuViewModel,
  ) {
    this.menu$ = authMenuViewModel.menu$;
  }

}
