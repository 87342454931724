import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  Either,
  createCommonErrors,
} from '@ui/jug-ui';
import { DataLayerErrorCode, UnionDataLayerErrors } from '../DataLayerErrorCode';


export const GDPR_ACCEPT_LOCAL_STORAGE_NAME = 'GDPR_ACCEPT';
export const GDPR_ACCEPT_LOCAL_STORAGE_VALUE = '+';


@Injectable()
export class GdprService {

  doWeNeedToShow(): Observable<Either<boolean, UnionDataLayerErrors>> {
    const acceptValue = window.localStorage.getItem(GDPR_ACCEPT_LOCAL_STORAGE_NAME);
    const doWeNeedToShow = acceptValue == null;
    return of(Either.right(doWeNeedToShow));
  }

  markAsAccepted(): Observable<Either<boolean, UnionDataLayerErrors>> {
    let wasSaved = true;
    try {
      window.localStorage.setItem(GDPR_ACCEPT_LOCAL_STORAGE_NAME, GDPR_ACCEPT_LOCAL_STORAGE_VALUE);
    } catch (e) {
      wasSaved = false;
    }

    const resultEither: Either<boolean, UnionDataLayerErrors> = wasSaved
      ? Either.right(true)
      : Either.left(
        createCommonErrors(DataLayerErrorCode.GDPR_WAS_NOT_SAVED),
      );

    return of(resultEither);
  }
}
