import { map } from 'rxjs/operators';
import { Either } from '@ui/jug-ui';
import { APIResponse } from './APIResponse';
import { APIErrors } from './APIError';
import { APIResponseStatus } from './APIResponseStatus';


export function eitherifyAPIServiceResponse<K>() {
  return map<APIResponse<K>, Either<K, APIErrors>>(apiResponse => {
    if (apiResponse.status === APIResponseStatus.ERROR) {
      return Either.left(apiResponse.errors);
    }
    return Either.right(apiResponse.response);
  });
}
