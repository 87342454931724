/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../../../node_modules/@ui/jug-ui/ui-jug-ui.ngfactory";
import * as i6 from "@ui/jug-ui";
import * as i7 from "../../../../components/icons/triangle-icon/triangle-icon.component.ngfactory";
import * as i8 from "../../../../components/icons/triangle-icon/triangle-icon.component";
import * as i9 from "./main-menu.component";
import * as i10 from "../../../../models/MainMenu.view-model";
var styles_MainMenuComponent = [i0.styles];
var RenderType_MainMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainMenuComponent, data: {} });
export { RenderType_MainMenuComponent as RenderType_MainMenuComponent };
function View_MainMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "a", [["class", "main-menu-submenu-item"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, [[3, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 2, { links: 1 }), i1.ɵqud(603979776, 3, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = _v.context.$implicit.link.queryParams; var currVal_3 = _v.context.$implicit.link.link; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.routeOptions; var currVal_5 = "active"; _ck(_v, 3, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_6 = (_v.context.$implicit.text ? _v.context.$implicit.text : i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.key))); _ck(_v, 6, 0, currVal_6); }); }
function View_MainMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "main-menu-submenus"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainMenuComponent_5)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.subItems; _ck(_v, 2, 0, currVal_0); }, null); }
function View_MainMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "main-menu-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "jc-tooltip-target", [["class", "main-menu-submenus-target"]], [[2, "tooltip-showed", null]], [[null, "mouseenter"], [null, "mouseleave"], [null, "touchstart"], [null, "touchmove"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onTouchStart() !== false);
        ad = (pd_2 && ad);
    } if (("touchmove" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onTouchMove() !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (i1.ɵnov(_v, 3).onClicked() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i5.View_TooltipTargetComponent_0, i5.RenderType_TooltipTargetComponent)), i1.ɵdid(3, 180224, null, 1, i6.TooltipTargetComponent, [i6.GlobalTouchListeners, i1.ElementRef, i1.ChangeDetectorRef], { closeOnClick: [0, "closeOnClick"] }, null), i1.ɵqud(335544320, 1, { tooltip: 0 }), (_l()(), i1.ɵand(0, null, 0, 1, null, View_MainMenuComponent_4)), i1.ɵdid(6, 16384, [[1, 4]], 0, i6.TooltipSlotDirective, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "main-menu-item_main"], ["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, [[4, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(9, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 4, { links: 1 }), i1.ɵqud(603979776, 5, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-triangle-icon", [["class", "main-menu-item_icon"]], null, null, null, i7.View_TriangleIconComponent_0, i7.RenderType_TriangleIconComponent)), i1.ɵdid(15, 49152, null, 0, i8.TriangleIconComponent, [], null, null)], function (_ck, _v) { var currVal_1 = true; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.link.queryParams; var currVal_3 = _v.parent.context.$implicit.link.link; _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = _v.parent.context.$implicit.routeOptions; var currVal_5 = "active"; _ck(_v, 9, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).showed; _ck(_v, 2, 0, currVal_0); var currVal_6 = (_v.parent.context.$implicit.text ? _v.parent.context.$implicit.text : i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(_v.parent.context.$implicit.key))); _ck(_v, 12, 0, currVal_6); }); }
function View_MainMenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "main-menu-item_main"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.outUrl; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_v.parent.parent.context.$implicit.text ? _v.parent.parent.context.$implicit.text : i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.parent.parent.context.$implicit.key))); _ck(_v, 2, 0, currVal_1); }); }
function View_MainMenuComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["activeRouteClass", "active"], ["class", "main-menu-item_main"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 0, i6.ActiveRouteCheckerDirective, [i2.Router, i1.ElementRef, i1.Renderer2], { jcActiveRouteChecker: [0, "jcActiveRouteChecker"], activeRouteCheckingOptions: [1, "activeRouteCheckingOptions"], activeRouteExtraLink: [2, "activeRouteExtraLink"], activeRouteClass: [3, "activeRouteClass"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = _v.parent.parent.context.$implicit.link.queryParams; var currVal_3 = _v.parent.parent.context.$implicit.link.link; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _v.parent.parent.context.$implicit.link; var currVal_5 = _v.parent.parent.context.$implicit.routeOptions; var currVal_6 = _v.parent.parent.context.$implicit.extraActiveLink; var currVal_7 = "active"; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_8 = (_v.parent.parent.context.$implicit.text ? _v.parent.parent.context.$implicit.text : i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.parent.parent.context.$implicit.key))); _ck(_v, 4, 0, currVal_8); }); }
function View_MainMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "main-menu-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainMenuComponent_7)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainMenuComponent_8)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.outUrl; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.parent.context.$implicit.outUrl; _ck(_v, 4, 0, currVal_1); }, null); }
function View_MainMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainMenuComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["itemAsLink", 2]], null, 0, null, View_MainMenuComponent_6))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.subItems.length; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_MainMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainMenuComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.ngIf.items; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MainMenuComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_MainMenuComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.menu$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MainMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main-menu", [], null, null, null, View_MainMenuComponent_0, RenderType_MainMenuComponent)), i1.ɵdid(1, 49152, null, 0, i9.MainMenuComponent, [i10.MainMenuViewModel], null, null)], null, null); }
var MainMenuComponentNgFactory = i1.ɵccf("app-main-menu", i9.MainMenuComponent, View_MainMenuComponent_Host_0, {}, {}, []);
export { MainMenuComponentNgFactory as MainMenuComponentNgFactory };
