import { NavigationService } from '../../routing/navigation.service';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ROUTES_PATHS } from '../../routing/navigation.consts';
import { Injectable } from '@angular/core';


@Injectable()
export class OAuthViewModel {

  isInOAuthZone$ = new BehaviorSubject(false);

  constructor(
    private navigationService: NavigationService,
    private router: Router,
  ) {
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => this.isOAuthUrl(event.url)),
      )
      .subscribe(this.isInOAuthZone$);

    const currentUrl = this.navigationService.getCurrentRouterUrl();
    const isOAuthUrl = this.isOAuthUrl(currentUrl);

    this.isInOAuthZone$.next(isOAuthUrl);
  }

  private isOAuthUrl(url: string)  {
    return url.indexOf(ROUTES_PATHS.oauth[0]) === 0;
  }
}
