import { Injectable } from '@angular/core';
import { CurrentUserModel } from '../../models/user/current-user.model';
import { API_ERRORS_INTERCEPTOR_TOKEN, APIErrorsHandlerInterceptor } from '../api/APIErrorsHandlerInterceptor';
import { APIResponse } from '../api/APIResponse';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { getFirstErrorCode } from '@ui/jug-ui';
import { APIErrorCode } from '../api/APIErrorCode';
import { AuthService } from '../auth/auth.service';


@Injectable()
export class ProjectAPIErrorsInterceptor implements APIErrorsHandlerInterceptor {

  constructor(
    private currentUserModel: CurrentUserModel,
    private authService: AuthService,
  ) {}

  intercept<K>(response: APIResponse<K>): Observable<APIResponse<K>> {
    return this.currentUserModel.isLoggedIn.stream$
      .pipe(
        first(),
        tap(isLoggedIn => {
          const firstErrorCode = getFirstErrorCode(response.errors);

          if (firstErrorCode === APIErrorCode.AUTHENTICATION_FAILED
            && isLoggedIn
          ) {
            this.authService.doLogout();
          }

        }),
        map(() => response),
      );
  }
}


export const projectAPIErrorsHandlerProvider = {
  provide: API_ERRORS_INTERCEPTOR_TOKEN,
  useClass: ProjectAPIErrorsInterceptor,
  multi: false,
};
