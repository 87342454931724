import { CommonError, CommonErrors } from '@ui/jug-ui';
import { APIError, APIErrors } from './api/APIError';
import { APIErrorCode } from './api/APIErrorCode';
import { CompanyOrderTicketDistributionStatuses } from './api/models/company/orders/CompanyOrderTicketDistributionAPIModel';


export enum DataLayerErrorCode {

  REGISTRATION_VERIFICATION_HAS_NOT_KEY = 'CL:DL:REGISTRATION_VERIFICATION_HAS_NOT_KEY',
  REGISTRATION_VERIFICATION_TOKEN_NOT_FOUND = 'CL:DL:REGISTRATION_VERIFICATION_TOKEN_NOT_FOUND',
  REGISTRATION_VERIFICATION_TOKEN_EXPIRED = 'CL:DL:REGISTRATION_VERIFICATION_TOKEN_EXPIRED',
  REGISTRATION_VERIFICATION_TOKEN_WAS_USED = 'CL:DL:REGISTRATION_VERIFICATION_TOKEN_WAS_USED',

  RESTORATION_VERIFICATION_HAS_NOT_KEY = 'CL:DL:RESTORATION_VERIFICATION_HAS_NOT_KEY',
  RESTORATION_VERIFICATION_TOKEN_NOT_FOUND = 'CL:DL:RESTORATION_VERIFICATION_TOKEN_NOT_FOUND',
  RESTORATION_VERIFICATION_TOKEN_EXPIRED = 'CL:DL:RESTORATION_VERIFICATION_TOKEN_EXPIRED',
  RESTORATION_VERIFICATION_TOKEN_WAS_USED = 'CL:DL:RESTORATION_VERIFICATION_TOKEN_WAS_USED',

  USER_LOGIN_DATA_IS_INCORRECT = 'CL:DL:AUTH_USER_DATA_IS_INCORRECT',
  USER_PROFILE_CANT_CHANGE_PASSWORD_WITHOUT_PASSWORD_OR_TOKEN = 'CL:DL:USER_PROFILE_CANT_CHANGE_PASSWORD_WITHOUT_PASSWORD_OR_TOKEN',
  USER_NOT_LOGGED_IN = 'CL:DL:USER_NOT_LOGGED_IN',

  REFERRAL_IS_NOT_PROVIDED = 'CL:DL:REFERRAL_IS_NOT_PROVIDED',

  GDPR_WAS_NOT_SAVED = 'CL:DL:GDPR_WAS_NOT_SAVED',

  TICKET_OFFER_NOT_FOUND = 'CL:CD:TICKET_OFFER_NOT_FOUND',

  COMPANY_ORDER_TICKET_DISTRIBUTION_QUOTA_EXCEEDED = 'CL:DL:COMPANY_ORDER_TICKET_DISTRIBUTION_QUOTA_EXCEEDED',
}


export type DataLayerError = CommonError<DataLayerErrorCode>;
export type DataLayerErrors = DataLayerError[];

export type UnionDataLayerErrorCode = DataLayerErrorCode|APIErrorCode;
export type UnionDataLayerError = DataLayerError|APIError;
export type UnionDataLayerErrors = CommonErrors<UnionDataLayerErrorCode>;
export type UnionDataLayerAPIErrors = APIErrors<UnionDataLayerErrorCode>;


