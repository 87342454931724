import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from './ui/pages/main/logged-in-guard.service';
import { AuthPartGuard } from './ui/pages/auth/auth-part-guard.service';
import { NotFoundRouteGuardService } from './routing/not-found-route-guard.service';
import { NotFoundRouteComponent } from './ui/pages/404/not-found-route/not-found-route.component';
import { OAuthPartGuard } from './ui/pages/oauth/oauth-part-guard.services';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./ui/pages/main/main.module').then(m => m.MainModule),
    canActivate: [ LoggedInGuard ],
  },

  {
    path: 'auth',
    loadChildren: () => import('./ui/pages/auth/auth.module').then(m => m.AuthModule),
    canActivate: [ AuthPartGuard ]
  },

  {
    path: 'oauth',
    loadChildren: () => import('./ui/pages/oauth/oauth.module').then(m => m.OAuthModule),
    canActivate: [ OAuthPartGuard ],
  },

  {
    path: 'acceptance',
    loadChildren: () => import('./ui/pages/acceptance/acceptance-start.module').then(m => m.AcceptanceStartModule),
  },
  {
    path: 'smartredirect',
    loadChildren: () => import('./ui/pages/smartredirect/smartredirect.module').then(m => m.SmartRedirectModule),
  },
  {
    path: '**',
    // use empty component and guard for logging this situation. It'll help us to understand wrong links
    component: NotFoundRouteComponent,
    canActivate: [ NotFoundRouteGuardService ]
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
