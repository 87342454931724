import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractTargetableComponent } from '@ui/jug-ui';


@Component({
  selector: 'app-notification-action',
  templateUrl: './notification-action.component.html',
  styleUrls: ['./notification-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationActionComponent extends AbstractTargetableComponent {

  @Output()
  action = new EventEmitter<Event>();

  @Input()
  targetAriaLabel: string;

}
