import { APIModelTransformer } from '../../services/api/models/APIModelTransformer';
import { CurrentUserProfileAPIModel } from '../../services/api/models/user/CurrentUserProfileAPIModel';
import { createCurrentUserDataModelFromAPIResponse, CurrentUserDataModel } from './current-user.data-model';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';


@Injectable()
export class CurrentUserApiModelTransformer implements APIModelTransformer<CurrentUserProfileAPIModel, CurrentUserDataModel> {

  transform(apiDataModel: CurrentUserProfileAPIModel): CurrentUserDataModel {
    if (environment.integrationTesting) {
      console.log(`[CurrentUserApiModelTransformer] new user model from API is`, apiDataModel);
    }
    return createCurrentUserDataModelFromAPIResponse(
      apiDataModel,
    );
  }


}

