import { ProtectedSubjectWrapper } from '@ui/jug-ui';
import { NotificationDataModel } from './notification.data-model';
import { BehaviorSubject } from 'rxjs';


export class NotificationsModel {

  private listSubject = new BehaviorSubject<NotificationDataModel[]>([]);
  list = new ProtectedSubjectWrapper(null, this.listSubject);

  addNotification(notificationModel: NotificationDataModel) {
    const listSubject = this.listSubject;
    const newNotificationsList = listSubject.getValue().concat(notificationModel);
    listSubject.next(newNotificationsList);
    return this;
  }

  removeNotification(notificationId: string) {
    const listSubject = this.listSubject;
    const newNotificationsList = listSubject.getValue().filter(notification => notification.id !== notificationId);
    listSubject.next(newNotificationsList);
    return this;
  }

  getNotificationById(notificationId: string): NotificationDataModel {
    return this.listSubject.getValue().find(notification => notification.id === notificationId) || null;
  }

}

