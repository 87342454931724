import { Injectable } from '@angular/core';
import { createLocalizableModelField, LocalizableModelField } from '@ui/jug-ui';
import { environment } from '../../../../environments/environment';
import { ConfigType } from 'dayjs';


interface ConfigurationBirthdayDayInputBounds {
  min: ConfigType,
  max: ConfigType,
}


@Injectable()
export class ConfigurationService {

  private readonly outsideConferenceCalendarLink: LocalizableModelField;
  private readonly contactUsLink: LocalizableModelField;
  private readonly instructionLink: LocalizableModelField;

  private readonly termsOfUseLink: LocalizableModelField;
  private readonly termsOfUseServiceAgreementLink: LocalizableModelField;
  private readonly termsOfPhotoLink: LocalizableModelField;
  private readonly termsOfDiversityLink: LocalizableModelField;
  private readonly termsOfCoCLink: LocalizableModelField;

  private readonly privacyPolicyLink: LocalizableModelField;
  private readonly cookiePolicyLink: LocalizableModelField;
  private readonly bugReportLink: LocalizableModelField;
  private readonly distributionTicketsMessageLink: LocalizableModelField;

  private readonly oAuth2GoogleLink: string;
  private readonly oAuth2YandexLink: string;
  private readonly oAuth2VKIDLink: string;

  public readonly speakersSrmLink: string;

  constructor() {
    const configurationLinks = environment.configuration.links;

    this.outsideConferenceCalendarLink = createLocalizableModelField(
      configurationLinks.outsideCalendarRu,
      configurationLinks.outsideCalendarEn,
    );

    this.instructionLink = createLocalizableModelField(
      configurationLinks.instructionLinkRu,
      configurationLinks.instructionLinkEn,
    );

    this.contactUsLink = createLocalizableModelField(
      configurationLinks.contactUsLinkRu,
      configurationLinks.contactUsLinkEn,
    );

    this.termsOfUseLink = createLocalizableModelField(
      configurationLinks.termsOfUseRu,
      configurationLinks.termsOfUseEn,
    );

    this.termsOfUseServiceAgreementLink = createLocalizableModelField(
      configurationLinks.termsOfUseServiceAgreementRu,
      configurationLinks.termsOfUseServiceAgreementEn,
    );

    this.termsOfPhotoLink = createLocalizableModelField(
      configurationLinks.termsOfPhotoLinkRu,
      configurationLinks.termsOfPhotoLinkEn,
    );

    this.termsOfDiversityLink = createLocalizableModelField(
      configurationLinks.termsOfDiversityLinkRu,
      configurationLinks.termsOfDiversityLinkEn,
    );

    this.termsOfCoCLink = createLocalizableModelField(
      configurationLinks.termsOfCoCLinkRu,
      configurationLinks.termsOfCoCLinkEn,
    );

    this.privacyPolicyLink = createLocalizableModelField(
      configurationLinks.privacyPolicyRu,
      configurationLinks.privacyPolicyEn,
    );

    this.cookiePolicyLink = createLocalizableModelField(
      configurationLinks.cookiePolicyLinkRu,
      configurationLinks.cookiePolicyLinkEn,
    );

    this.bugReportLink = createLocalizableModelField(
      configurationLinks.bugReportLinkRu,
      configurationLinks.bugReportLinkEn,
    );

    this.distributionTicketsMessageLink = createLocalizableModelField(
      configurationLinks.distributionTicketsMessageLinkRu,
      configurationLinks.distributionTicketsMessageLinkEn,
    );

    this.oAuth2GoogleLink = configurationLinks.oAuth2GoogleLink;
    this.oAuth2YandexLink = configurationLinks.oAuth2YandexLink;
    this.oAuth2VKIDLink = configurationLinks.oAuth2VKIDLink;

    this.speakersSrmLink = configurationLinks.speakersSrmLink;
  }

  getOutsideConferenceCalendarLink(): LocalizableModelField {
    return this.outsideConferenceCalendarLink;
  }

  getContactUsLink() {
    return this.contactUsLink;
  }

  getInstructionLink() {
    return this.instructionLink;
  }

  getTermsOfUseLink() {
    return this.termsOfUseLink;
  }

  getTermsOfUseServiceAgreementLink() {
    return this.termsOfUseServiceAgreementLink;
  }

  getTermsOfPhotoLink() {
    return this.termsOfPhotoLink;
  }

  getTermsOfDiversityLink() {
    return this.termsOfDiversityLink;
  }

  getTermsOfCoCLink() {
    return this.termsOfCoCLink;
  }

  getPrivacyPolicyLink() {
    return this.privacyPolicyLink;
  }

  getBirthdayDayInputBounds(): ConfigurationBirthdayDayInputBounds {
    return {
      min: '1905-01-01',
      max: '2018-12-31',
    };
  }

  getDefaultNotificationsDurationTime() {
    return 10000;
  }

  getCookieBannerLink() {
    return this.cookiePolicyLink;
  }

  getBugReportLink() {
    return this.bugReportLink;
  }

  getDistributionTicketsMessageLink() {
    return this.distributionTicketsMessageLink;
  }

  getOAuth2GoogleLink() {
    return this.oAuth2GoogleLink;
  }

  getOAuth2YandexLink() {
    return this.oAuth2YandexLink;
  }

  getOAuth2VKIDLink() {
    return this.oAuth2VKIDLink;
  }
}
