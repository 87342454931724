import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from './navigation.service';


@Injectable()
export class NotFoundRouteGuardService implements CanActivate {

  constructor(
    private navigationService: NavigationService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.navigationService.navigateToMain(true);
    // TODO [dmitry.makhnev]: logging
    return false;
  }

}

