
<ng-container [ngSwitch]="currentLocale$ | async">

  <ng-container *ngSwitchCase="localeRu">
    <button
      #languageSwitchButton
      class="target"
      [attr.aria-label]="'languages.switch.en' | translate"
      (click)="switchToLanguage(localeEn)"
    >
    </button>
    <span class="text" aria-hidden="true">{{ 'languages.en' | translate }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="localeEn">
    <button
      #languageSwitchButton
      class="target"
      [attr.aria-label]="'languages.switch.ru' | translate"
      (click)="switchToLanguage(localeRu)"
    >
    </button>
    <span class="text" aria-hidden="true">{{ 'languages.ru' | translate }}</span>
  </ng-container>

</ng-container>

