/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./language-switcher.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./language-switcher.component";
import * as i5 from "@ui/jug-ui";
import * as i6 from "../../../data/services/user/profile/user-profile.service";
var styles_LanguageSwitcherComponent = [i0.styles];
var RenderType_LanguageSwitcherComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LanguageSwitcherComponent, data: {} });
export { RenderType_LanguageSwitcherComponent as RenderType_LanguageSwitcherComponent };
function View_LanguageSwitcherComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["languageSwitchButton", 1]], null, 1, "button", [["class", "target"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchToLanguage(_co.localeEn) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["aria-hidden", "true"], ["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("languages.switch.en")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("languages.en")); _ck(_v, 4, 0, currVal_1); }); }
function View_LanguageSwitcherComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["languageSwitchButton", 1]], null, 1, "button", [["class", "target"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchToLanguage(_co.localeRu) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["aria-hidden", "true"], ["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("languages.switch.ru")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("languages.ru")); _ck(_v, 4, 0, currVal_1); }); }
export function View_LanguageSwitcherComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { languageSwitchButton: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i3.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageSwitcherComponent_1)), i1.ɵdid(5, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LanguageSwitcherComponent_2)), i1.ɵdid(7, 278528, null, 0, i3.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i3.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.currentLocale$)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.localeRu; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.localeEn; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_LanguageSwitcherComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-language-switcher", [], null, null, null, View_LanguageSwitcherComponent_0, RenderType_LanguageSwitcherComponent)), i1.ɵdid(1, 8568832, null, 0, i4.LanguageSwitcherComponent, [i5.I18nService, i6.UserProfileService], null, null)], null, null); }
var LanguageSwitcherComponentNgFactory = i1.ɵccf("app-language-switcher", i4.LanguageSwitcherComponent, View_LanguageSwitcherComponent_Host_0, {}, {}, []);
export { LanguageSwitcherComponentNgFactory as LanguageSwitcherComponentNgFactory };
