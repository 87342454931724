
<div
  class="visual"
  [attr.aria-hidden]="isOpenedFlag ? 'false' : 'true'"
>
  <div class="content">

    <div
      *ngIf="(menu$ | async) as menu"
      class="main-menu"
    >
      <ng-container *ngFor="let menuItem of menu.items">
        <ng-container *ngIf="menuItem.subItems.length; else itemAsLink">
          <a
            [routerLink]="menuItem.link.link"
            [queryParams]="menuItem.link.queryParams"
            [routerLinkActiveOptions]="menuItem.routeOptions"
            routerLinkActive
            #submenuActive="routerLinkActive"
            style="display: none"
          ></a>
          <div
            [ngClass]="{
              'main-menu-item': true,
              '__submenu-opener': true,
              '__open': (openedMenuItem$ | async) === menuItem,
              'active': submenuActive.isActive
            }"
            (click)="toggleSubmenu($event, menuItem)"
          >
            {{ menuItem.text ? menuItem.text : menuItem.key | translate }}
            <app-triangle-icon class="main-menu-item_icon"></app-triangle-icon>
          </div>
          <div
            [ngClass]="{
              'main-menu-item_submenu': true,
              '__open': (openedMenuItem$ | async) === menuItem
            }"
          >
            <a
              class="main-menu-sub-item"
              *ngFor="let subItem of menuItem.subItems"
              [routerLink]="subItem.link.link"
              [queryParams]="subItem.link.queryParams"
              [routerLinkActiveOptions]="subItem.routeOptions"
              routerLinkActive="active"
              [attr.data-view-type]=" subItem.appUIPartViewType ? subItem.appUIPartViewType : null "
            >
              {{ subItem.text ? subItem.text : subItem.key | translate }}
            </a>
          </div>
        </ng-container>
        <ng-template #itemAsLink>
          <ng-container *ngIf="menuItem.outUrl">
            <a
              class="main-menu-item"
              [href]="menuItem.outUrl"
            >
              {{ menuItem.text ? menuItem.text : menuItem.key | translate }}
            </a>
          </ng-container>

          <ng-container *ngIf="!menuItem.outUrl">
            <a
              class="main-menu-item"
              [routerLink]="menuItem.link.link"
              [queryParams]="menuItem.link.queryParams"
              [routerLinkActiveOptions]="menuItem.routeOptions"
              routerLinkActive="active"
            >
              {{ menuItem.text ? menuItem.text : menuItem.key | translate }}
            </a>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>

    <div class="extra">
      <a
        class="user-email-link"
        *ngIf="user"
        [routerLink]="userInfoLink.link"
        [queryParams]="userInfoLink.queryParams"
      >
        {{ displayName }}
      </a>

      <jc-action
        class="exit"
        (action)="logout.emit($event)"
        [targetAriaLabel]="'header.logoutAction' | translate"
      >
        <jc-exit-icon></jc-exit-icon>
      </jc-action>
    </div>

  </div>
</div>

