import { Injectable } from '@angular/core';
import { NotificationActionHandler } from '../../../../data/services/notifications/NotificationActionHandler';
import { NotificationDataModel } from '../../../../data/models/notifications/notification.data-model';
import { GdprService } from '../../../../data/services/gdpr/gdpr.service';


@Injectable()
export class GDPRCookieNotificationActionHandler implements NotificationActionHandler {

  constructor(
    private gdprService: GdprService,
  ) {}

  handleAction(notification: NotificationDataModel): void {
    this.gdprService.markAsAccepted();
  }

}

