import {
  APICommandsMapping,
  APICommandRequestsMapping,
  APICommandResponsesMapping
} from '../commands/APICommandsMapping';


class MigrationAPITransformer {

  transformRequest<K extends keyof APICommandsMapping>(
    command: K,
    commandData: APICommandRequestsMapping[K]
  ): APICommandRequestsMapping[K] {

    switch (command) {

    }

    return commandData;
  }

  transformResponse<K extends keyof APICommandsMapping>(
    command: K,
    commandResponse: APICommandResponsesMapping[K],
    commandData: APICommandRequestsMapping[K],
  ): APICommandResponsesMapping[K] {
    switch (command) {

    }

    return commandResponse;
  }

}


export const migrationAPITransformer = new MigrationAPITransformer();

