


export enum NotificationType {
  FIST_USER_DATA_FILLING_SUCCESS = 'FIST_USER_DATA_FILLING_SUCCESS',

  REGISTRATION_WAS_COMPLETED_SECOND_TIME = 'REGISTRATION_WAS_COMPLETED_SECOND_TIME',

  USER_PASSWORD_WAS_SET_FOR_RESTORATION = 'USER_PASSWORD_WAS_SET_FOR_RESTORATION',

  USER_PASSWORD_WAS_SET = 'USER_PASSWORD_WAS_SET',

  USER_PROFILE_WAS_SAVED = 'USER_PROFILE_WAS_SAVED',

  GDPR_COOKIE_USAGE = 'GDPR_COOKIE_USAGE',

  TICKET_OFFER_WAS_DECLINED = 'TICKET_OFFER_WAS_DECLINED',

  USER_PROFILE_NEW_CONTACT_WAS_ADDED = 'USER_PROFILE_NEW_CONTACT_WAS_ADDED',
  USER_PROFILE_CONTACT_WAS_SET_DEFAULT = 'USER_PROFILE_CONTACT_WAS_SET_DEFAULT',
  USER_PROFILE_CONTACT_WAS_REMOVED = 'USER_PROFILE_CONTACT_WAS_REMOVED',
  USER_PROFILE_CONTACT_WAS_EDITED = 'USER_PROFILE_CONTACT_WAS_EDITED',

  ONE_TIME_CODE_FOR_CHANGE_PASSWORD_WAS_SENT = 'ONE_TIME_CODE_FOR_CHANGE_PASSWORD_WAS_SENT',
  SEND_ANOTHER_CODE = 'SEND_ANOTHER_CODE'
}

