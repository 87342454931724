import { UserRole } from '../../../data/models/user/UserRole';

const LOCALE_STORAGE_USER_PROFILE_ROLE_REPRESENTATION = 'lk.jugru.org.user-profile-role-representation';


export const saveUserProfileRoleRepresentationToLocaleStorage = (role: UserRole) => {
  if (typeof localStorage === 'undefined') {
    return;
  }

  localStorage.setItem(LOCALE_STORAGE_USER_PROFILE_ROLE_REPRESENTATION, role);
};

export const getUserProfileRoleRepresentationFromLocaleStorage = (): UserRole | undefined => {
  if (typeof localStorage === 'undefined') {
    return;
  }

  const role = localStorage.getItem(LOCALE_STORAGE_USER_PROFILE_ROLE_REPRESENTATION);

  if (!role) {
    return;
  }

  return role as UserRole;
};
