import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-triangle-icon',
  templateUrl: './triangle-icon.component.html',
  styleUrls: ['./triangle-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TriangleIconComponent {

}
