/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-action.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@ui/jug-ui/ui-jug-ui.ngfactory";
import * as i3 from "@ui/jug-ui";
import * as i4 from "./notification-action.component";
var styles_NotificationActionComponent = [i0.styles];
var RenderType_NotificationActionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationActionComponent, data: {} });
export { RenderType_NotificationActionComponent as RenderType_NotificationActionComponent };
export function View_NotificationActionComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "jc-action", [], [[2, "__active", null]], [[null, "action"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.action.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (_co.focus.emit($event) !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = (_co.blur.emit($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ActionComponent_0, i2.RenderType_ActionComponent)), i1.ɵdid(1, 49152, null, 0, i3.ActionComponent, [], { type: [0, "type"], disabled: [1, "disabled"], targetAriaLabel: [2, "targetAriaLabel"], link: [3, "link"], href: [4, "href"], target: [5, "target"] }, { action: "action", focus: "focus", blur: "blur" }), i1.ɵncd(0, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.type; var currVal_2 = _co.disabled; var currVal_3 = _co.targetAriaLabel; var currVal_4 = _co.link; var currVal_5 = _co.href; var currVal_6 = _co.target; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isActiveCache; _ck(_v, 0, 0, currVal_0); }); }
export function View_NotificationActionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-action", [], null, null, null, View_NotificationActionComponent_0, RenderType_NotificationActionComponent)), i1.ɵdid(1, 49152, null, 0, i4.NotificationActionComponent, [], null, null)], null, null); }
var NotificationActionComponentNgFactory = i1.ɵccf("app-notification-action", i4.NotificationActionComponent, View_NotificationActionComponent_Host_0, { type: "type", disabled: "disabled", targetAriaLabel: "targetAriaLabel", link: "link", href: "href", target: "target", actionRouterLinkActive: "actionRouterLinkActive", actionRouterLinkActiveOptions: "actionRouterLinkActiveOptions" }, { action: "action", focus: "focus", blur: "blur" }, ["*"]);
export { NotificationActionComponentNgFactory as NotificationActionComponentNgFactory };
