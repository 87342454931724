/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./triangle-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./triangle-icon.component";
var styles_TriangleIconComponent = [i0.styles];
var RenderType_TriangleIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TriangleIconComponent, data: {} });
export { RenderType_TriangleIconComponent as RenderType_TriangleIconComponent };
export function View_TriangleIconComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["viewBox", "0 0 452 258"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M203.6 248.7L9.3 54.1C-3.1 41.7-3.1 21.7 9.3 9.3 21.6-3.1 41.7-3.1 54 9.3l172 172.2L398 9.3c12.4-12.4 32.4-12.4 44.8 0 12.4 12.4 12.4 32.4 0 44.8L248.4 248.7c-6.2 6.2-14.3 9.3-22.4 9.3-8.1 0-16.2-3.1-22.4-9.3z"]], null, null, null, null, null))], null, null); }
export function View_TriangleIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-triangle-icon", [], null, null, null, View_TriangleIconComponent_0, RenderType_TriangleIconComponent)), i1.ɵdid(1, 49152, null, 0, i2.TriangleIconComponent, [], null, null)], null, null); }
var TriangleIconComponentNgFactory = i1.ɵccf("app-triangle-icon", i2.TriangleIconComponent, View_TriangleIconComponent_Host_0, {}, {}, []);
export { TriangleIconComponentNgFactory as TriangleIconComponentNgFactory };
