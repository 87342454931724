import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { CurrentUserDataModel } from '../../../data/models/user/current-user.data-model';
import { AuthService } from '../../../data/services/auth/auth.service';
import { CurrentUserModel } from '../../../data/models/user/current-user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationService } from '../../../routing/navigation.service';
import { NavigationRouterLink, GlobalTouchListeners } from '@ui/jug-ui';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigurationService } from '../../../data/services/configuration/configuration.service';

import { map } from 'rxjs/operators';

import { createDisplayName } from './createDisplayName';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {

  MOBILE_MENU_ELEMENT = '__MOBILE_MENU_ELEMENT__';

  isLoggedIn$: Observable<boolean>;
  currentUserDataModel$: Observable<CurrentUserDataModel>;

  userInfoLink: NavigationRouterLink;
  linkToMain: NavigationRouterLink;

  mobileMenuOpen$ = new BehaviorSubject(false);

  constructor(
    private currentUserModel: CurrentUserModel,
    private authService: AuthService,
    private navigationService: NavigationService,
    private router: Router,
    private globalTouchListeners: GlobalTouchListeners,
    public configurationService: ConfigurationService,
  ) {
    this.isLoggedIn$ = currentUserModel.isLoggedIn.stream$;
    this.currentUserDataModel$ = currentUserModel.user.stream$;

    this.userInfoLink = navigationService.getLinkToUserProfileInfo();
    this.linkToMain = navigationService.getLinkToMain();

    this.startControlMobileMenu();
  }

  public get displayName(): Observable<string> {
    return this.currentUserDataModel$.pipe(
      map(userModel => createDisplayName(
          userModel.nickName,
          userModel.firstName,
          userModel.familyName,
          userModel.email,
        ))
    );
  }

  onMobileMenuToggle() {
    this.mobileMenuOpen$.next(!this.mobileMenuOpen$.getValue());
  }

  private tryToCloseMenu() {
    if (this.isMobileMenuOpen) {
      this.mobileMenuOpen$.next(false);
    }
  }

  private get isMobileMenuOpen() {
    const isMenuOpen = this.mobileMenuOpen$.getValue();
    return isMenuOpen;
  }

  private isElementForMobileMenu(element: Element) {
    return element.closest(`.${this.MOBILE_MENU_ELEMENT}`);
  }

  private startControlMobileMenu() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.tryToCloseMenu();
      }
    });
    this.globalTouchListeners.onTouchStart.subscribe(event  => {
      if (this.isMobileMenuOpen && !this.isElementForMobileMenu(event.target)) {
        this.tryToCloseMenu();
      }
    });
  }

  @HostBinding('class.__has-second-level-menu')
  hasSecondLevelMenu = false;

  onSubMenuMounted() {
    this.hasSecondLevelMenu = true;
  }

  onSubMenuUnmounted() {
    this.hasSecondLevelMenu = false;
  }

  @HostBinding('class.__has-mobile-navigation')
  hasMobileNavigation = false;

  onMobileNavigationMounted() {
    this.hasMobileNavigation = true;
  }

  onMobileNavigationUnmounted() {
    this.hasMobileNavigation = false;
  }

  @HostBinding('class.__has-mobile-second-level-navigation')
  hasMobileSecondLevelNavigation = false;

  onMobileSecondLevelNavigationMounted() {
    this.hasMobileSecondLevelNavigation = true;
  }

  onMobileSecondLevelNavigationUnmounted() {
    this.hasMobileSecondLevelNavigation = false;
  }

  logout() {
    this.authService
      .logout()
      .subscribe();
  }
}
