<jc-action
  [type]="type"
  [href]="href"
  [target]="target"
  [link]="link"
  [disabled]="disabled"
  [targetAriaLabel]="targetAriaLabel"
  (action)="action.emit($event)"
  (focus)="focus.emit($event)"
  (blur)="blur.emit($event)"
>
  <ng-content></ng-content>
</jc-action>
