import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuData } from '../MenuData';
import { MainMenuViewModel } from '../../../../models/MainMenu.view-model';


@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuComponent {

  menu$: Observable<MenuData>;

  constructor(
    private mainMenuViewModel: MainMenuViewModel,
  ) {
    this.menu$ = mainMenuViewModel.menu$;
  }

}
