import { AfterViewChecked, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { I18nService, Locale, tapEitherError } from '@ui/jug-ui';
import { UserProfileService } from '../../../data/services/user/profile/user-profile.service';


@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent implements AfterViewChecked, OnDestroy {

  currentLocale$: Observable<Locale>;

  localeRu = Locale.RU;
  localeEn = Locale.EN;

  @ViewChild('languageSwitchButton', { static: false }) languageSwitchButton;

  private _needRefocusLanguageSwitchingButton = false;
  private _focusControlSubscription: Subscription;

  constructor(
    private i18nService: I18nService,
    private userProfileService: UserProfileService,
  ) {
    this.currentLocale$ = i18nService.currentLocale$;
    this._focusControlSubscription = i18nService.currentLocale$
      .subscribe(
        () => {
          if (this.languageSwitchButton) {
            this._needRefocusLanguageSwitchingButton = this.languageSwitchButton.nativeElement === document.activeElement;
          }
        }
      );
  }

  ngAfterViewChecked() {
    if (this.languageSwitchButton && this._needRefocusLanguageSwitchingButton) {
      this._needRefocusLanguageSwitchingButton = false;
      this.languageSwitchButton.nativeElement.focus();
    }
  }

  ngOnDestroy() {
    this._focusControlSubscription.unsubscribe();
  }

  switchToLanguage(locale: Locale) {
    this.userProfileService
      .tryChangeLanguage(locale)
      .pipe(
        tapEitherError(errors => {
          console.log(errors);
        }),
      )
      .subscribe();
  }

}
