import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuData } from '../common/header/menus/MenuData';
import { NavigationService } from '../../routing/navigation.service';
import { createMenuItemData, MenuItemData } from '../common/header/menus/MenuItemData';
import { map } from 'rxjs/operators';
import { OAuthViewModel } from './OAuthViewModel';


@Injectable()
export class AuthMenuViewModel {
  private oldIsInOAuthZone?: boolean;

  menu$ = new BehaviorSubject<MenuData>(null);

  constructor(
    private navigationService: NavigationService,
    private oAuthViewModel: OAuthViewModel,
  ) {
    this.oAuthViewModel
      .isInOAuthZone$
      .pipe(
        map(isInOAuthZone => this.createMenu(isInOAuthZone)),
      )
      .subscribe(
        this.menu$,
      );
  }

  private createMenu(
    isInOAuthZone: boolean,
  ): MenuData {
    const navigationService = this.navigationService;
    const linkToLogin = navigationService.getLinkToLogin();
    const linkToRegistrationStart = navigationService.getLinkToRegistrationStart();
    const linkToOAuthLogin = navigationService.getLinkToOAuthLogin();

    const items: MenuItemData[] = [];

    items.push(
      createMenuItemData({
        link: (isInOAuthZone || this.oldIsInOAuthZone) ? linkToOAuthLogin : linkToLogin,
        key: 'login',
        extraActiveLink: {
          link: linkToOAuthLogin,
        },
      }),
    );

    items.push(
      createMenuItemData({
        link: linkToRegistrationStart,
        key: 'registration',
      }),
    );

    this.oldIsInOAuthZone = isInOAuthZone;

    return {
      items,
    };
  }

}
