import { Injectable } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';
import {
  DYNAMIC_ROUTES_PATHS, EMAIL_CONFIRMATION_TOKEN_PARAM_NAME, FROM_PARAM_NAME,
  LANGUAGE_URL_PARAM_NAME,
  OPENING_CONTEXT_PARAM_NAME,
  REFERRAL_URL_PARAM_NAME,
  REGISTRATION_CONFIRMATION_KEY_PARAM_NAME, RESTORATION_CONFIRMATION_KEY_PARAM_NAME,
  ROUTES_PATHS, TICKET_ACCEPTANCE_TOKEN_PARAM_NAME
} from './navigation.consts';
import { Locale, resolveLocaleByString, NavigationRouterLink, createNavigationLink } from '@ui/jug-ui';
import { CompanyOrderSelector } from '../data/models/company/order/company-order.selector';


export interface NavigationUrlModificationResult {
  url: UrlTree;
  extracted: {
    queryParams: object;
  };
}

const URL_PARAMS_WHICH_SHOULD_BE_REMOVING_FROM_AUTH_LINKS = [
  REGISTRATION_CONFIRMATION_KEY_PARAM_NAME,
  RESTORATION_CONFIRMATION_KEY_PARAM_NAME,
  LANGUAGE_URL_PARAM_NAME,
  TICKET_ACCEPTANCE_TOKEN_PARAM_NAME,
];

@Injectable()
export class NavigationService {

  constructor(private router: Router) {}

  modifyUrl(
    url: string|UrlTree,
    options: {
      remove?: {
        queryParams?: string[],
      },
      extract?: {
        queryParams?: string[],
      }
    }
  ): NavigationUrlModificationResult {
    let workingUrlTree;
    if (typeof url === 'string') {
      workingUrlTree = this.router.parseUrl(url);
    } else {
      // reparse URLTree for save provided url from mutations
      workingUrlTree = this.router.parseUrl(url.toString());
    }

    const workingUrlQueryParams = workingUrlTree.queryParams;

    const result = {
      url: workingUrlTree,
      extracted: {
        queryParams: {},
      },
    };

    if (options.extract && options.extract.queryParams) {
      const extractedQueryParams = result.extracted.queryParams;
      options.extract.queryParams.forEach(queryParamName => {
        if (workingUrlQueryParams.hasOwnProperty(queryParamName)) {
          extractedQueryParams[queryParamName] = workingUrlQueryParams[queryParamName];
        }
      });
    }

    if (options.remove && options.remove.queryParams) {
      options.remove.queryParams.forEach(queryParamName => {
        if (workingUrlQueryParams.hasOwnProperty(queryParamName)) {
          delete workingUrlQueryParams[queryParamName];
        }
      });
    }

    return result;
  }


  private getCurrentRouterStateSnapshot() {
    const routerState = this.router.routerState;
    return routerState.snapshot;
  }

  getParsedUrl(): URL {
    return new URL(location.href);
  }

  getCurrentRouterUrl(): string {
    const currentNavigation = this.router.getCurrentNavigation();
    if (
      currentNavigation
      && currentNavigation.extractedUrl
    ) {
      return currentNavigation.extractedUrl.toString();
    }
    return this.getCurrentRouterStateSnapshot().url;
  }

  /**
   * @description Attention this method works after application init angular...
   * @param removeParams
   */
  getCurrentRouterQueryParams(
    removeParams: string[] = null
  ): Params {
    const currentNavigation = this.router.getCurrentNavigation();

    let queryParams;
    if (removeParams) {
      const url = this.getCurrentRouterUrl();
      const modifiedUrl = this.modifyUrl(
        url,
        {
          remove: {
            queryParams: removeParams,
          },
        }
      );
      queryParams = modifiedUrl.url.queryParams;
    } else if (
      currentNavigation
      && currentNavigation.extractedUrl
    ) {
      queryParams = currentNavigation.extractedUrl.queryParams;
    } else {
      queryParams = this.getCurrentRouterStateSnapshot().root.queryParams;
    }

    return queryParams;
  }

  getLinkToRegistrationStart(
    options?: {
      withCurrentContext?: boolean,
    }
  ): NavigationRouterLink {
    let resultQueryParams = this.getCurrentRouterQueryParams(URL_PARAMS_WHICH_SHOULD_BE_REMOVING_FROM_AUTH_LINKS);

    if (options && options.withCurrentContext) {
      resultQueryParams = {
        ...resultQueryParams,
        [OPENING_CONTEXT_PARAM_NAME]: this.getCurrentRouterUrl(),
      };
    }
    return createNavigationLink(
      ROUTES_PATHS.registrationStart,
      resultQueryParams,
    );
  }

  getLinkToRestorationStart(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.restorationStart,
      this.getCurrentRouterQueryParams(URL_PARAMS_WHICH_SHOULD_BE_REMOVING_FROM_AUTH_LINKS),
    );
  }

  getLinkToLogin(
    options?: {
      withCurrentContext?: boolean,
    }
  ): NavigationRouterLink {
    let resultQueryParams = this.getCurrentRouterQueryParams(URL_PARAMS_WHICH_SHOULD_BE_REMOVING_FROM_AUTH_LINKS);

    if (options && options.withCurrentContext) {
      resultQueryParams = {
        ...resultQueryParams,
        [OPENING_CONTEXT_PARAM_NAME]: this.getCurrentRouterUrl(),
      };
    }

    return createNavigationLink(
      ROUTES_PATHS.login,
      resultQueryParams,
    );
  }

  getLinkToOAuthLogin() {
    return createNavigationLink(
      ROUTES_PATHS.oauthLogin,
    );
  }

  getLinkToUserProfileInfo(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.userProfileInfo,
    );
  }

  getLinkToTickets(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.tickets,
    );
  }

  getLinkToTicketsOffers(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.ticketsOffers,
    );
  }

  getLinkToCompanies(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.companies,
    );
  }

  getLinkToCompany(companyId: string): NavigationRouterLink {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.company(companyId),
    );
  }

  getLinkToCompanyOrders(companyId: string) {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.companyOrders(companyId),
    );
  }

  getLinkToCompanyOrder(
    companyOrderSelector: CompanyOrderSelector,
  ) {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.companyOrder(
        companyOrderSelector.companyId,
        companyOrderSelector.orderId,
      ),
    );
  }

  getLinkToAdminCompanyOrder(
    companyOrderSelector: CompanyOrderSelector,
  ) {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.administrationCompanyOrder(
        companyOrderSelector.companyId,
        companyOrderSelector.orderId,
      ),
    );
  }

  getLinkToCompanyOrderTickets(
    companyOrderSelector: CompanyOrderSelector,
  ) {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.companyOrderTickets(
        companyOrderSelector.companyId,
        companyOrderSelector.orderId,
      ),
    );
  }

  getLinkToCompanyOrderInformation(
    companyOrderSelector: CompanyOrderSelector,
  ) {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.companyOrderInformation(
        companyOrderSelector.companyId,
        companyOrderSelector.orderId,
      ),
    );
  }

  navigateToCompanyOrderInformation(
    companyOrderSelector: CompanyOrderSelector,
    isRedirect: boolean = false,
    queryParams: Object = {},
  ) {
    return this.router.navigate(
      DYNAMIC_ROUTES_PATHS.companyOrderInformation(
        companyOrderSelector.companyId,
        companyOrderSelector.orderId,
      ),
      {
        replaceUrl: isRedirect,
        queryParams,
      }
    );
  }

  navigateToAdministrationCompanyOrderInformation(
    companyOrderSelector: CompanyOrderSelector,
    isRedirect: boolean = false,
    queryParams: Object = {},
  ) {
    return this.router.navigate(
      DYNAMIC_ROUTES_PATHS.administrationCompanyOrder(
        companyOrderSelector.companyId,
        companyOrderSelector.orderId,
      ),
      {
        replaceUrl: isRedirect,
        queryParams,
      }
    );
  }

  navigateToAdministration(
    isRedirect: boolean = false,
    queryParams: Object = {},
  ) {
    this.router.navigate(
      ROUTES_PATHS.administration,
      {
        replaceUrl: isRedirect,
        queryParams,
      }
    );
  }

  navigateToCompany(
    companyId: number,
    isRedirect: boolean = false,
    queryParams: Object = {},
  ) {
    this.router.navigate(
      DYNAMIC_ROUTES_PATHS.company(companyId),
      {
        replaceUrl: isRedirect,
        queryParams,
      }
    );
  }

  navigateToCorporateCompanies(
    isRedirect: boolean = false,
    queryParams: Object = {},
  ) {
    this.router.navigate(
      ROUTES_PATHS.companies,
      {
        replaceUrl: isRedirect,
        queryParams,
      }
    );
  }

  getLinkToCompanyProfile(companyId: string) {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.companyProfile(companyId),
    );
  }

  getLinkToAdministration(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.administration,
    );
  }

  getLinkToAdministrationCompany(companyId: string): NavigationRouterLink {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.administrationCompanyOrders(companyId),
    );
  }

  getMergeLkLink(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.mergeLk
    );
  }

  getBaseUserProfileLink(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.userProfileBaseInfo,
    );
  }

  getContactsUserProfileLink(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.userProfileContactsInfo,
    );
  }

  getSecurityBaseUserProfileLink(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.userProfileSecurityInfo,
    );
  }

  getUserShareProfileLink(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.userProfileShareInfo,
    );
  }

  getEditingBaseUserProfileLink(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.userEditingBaseProfile,
    );
  }

  getEditingUserProfileContactsLink(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.userEditingProfileContacts,
    );
  }

  getLinkToUserChangePassword(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.userPasswordChanging,
    );
  }

  getLinkToMain(): NavigationRouterLink {
    return createNavigationLink(
      ROUTES_PATHS.main,
    );
  }

  getLinkToAdminCompanyOrders(companyId: number): NavigationRouterLink {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.administrationCompanyOrders(companyId),
    );
  }

  getLinkToAdminCompanyProfile(companyId: number): NavigationRouterLink {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.administrationCompanyProfile(companyId),
    );
  }

  getLinkToAdminCompanyRoles(companyId: number): NavigationRouterLink {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.administrationCompanyRoles(companyId),
    );
  }

  getLinkToAdminCompanyOrderTickets(companyId: number, orderId: number): NavigationRouterLink {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.administrationCompanyOrderTickets(companyId, orderId)
    );
  }

  getLinkToAdminCompanyOrderInformation(companyId: number, orderId: number): NavigationRouterLink {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.administrationCompanyOrderInformation(companyId, orderId)
    );
  }

  getOpeningContextUrl(): string {
    return this.getCurrentRouterQueryParams()[OPENING_CONTEXT_PARAM_NAME] || null;
  }

  navigateToUrlByLocation(url: string) {
    window.location.href = url;
  }

  navigateToMain(
    isRedirect: boolean = false,
    queryParams: object = {},
  ) {
    return this.router.navigate(
      ROUTES_PATHS.main,
      {
        replaceUrl: isRedirect,
        queryParams,
      }
    );
  }

  navigateToMergeLk(
    isRedirect: boolean = false,
    queryParams: Object = {},
  ) {
    return this.router.navigate(
      ROUTES_PATHS.mergeLk,
      {
        replaceUrl: isRedirect,
        queryParams,
      }
    );
  }

  navigateToTickets(
    isRedirect: boolean = false,
    queryParams: Object = {},
  ) {
    this.router.navigate(
      ROUTES_PATHS.tickets,
      {
        replaceUrl: isRedirect,
        queryParams,
      }
    );
  }

  navigateToUserProfileInfo(
    isRedirect: boolean = false,
    queryParams: Object = {},
  ) {
    return this.router.navigate(
      ROUTES_PATHS.userProfileInfo,
      {
        replaceUrl: isRedirect,
        queryParams
      }
    );
  }

  navigateToUserProfileInfoWithRefresh() {
    window.location.href = ROUTES_PATHS.userProfileInfo[0];
  }

  navigateToEditingBaseUserProfile(
    isRedirect: boolean = false,
    queryParams: Object = {},
  ) {
    return this.router.navigate(
      ROUTES_PATHS.userEditingBaseProfile,
      {
        replaceUrl: isRedirect,
        queryParams
      },
    )
  }

  navigateToAuthorizedPartWithOpeningContext(
    isRedirect: boolean = false,
  ) {
    const openingContextUrl = this.getOpeningContextUrl();
    if (openingContextUrl) {
      return this.router.navigateByUrl(
        openingContextUrl,
        {
          replaceUrl: isRedirect,
        }
      );
    }

    return this.navigateToMain(isRedirect);
  }

  navigateToAuthorize(
    isRedirect: boolean = false,
    options: {
      saveCurrentContext?: boolean,
      openingContextUrl?: string,
      referral?: string,
    } = {}
  ) {
    let openingContextUrl: string;
    if (options.openingContextUrl) {
      openingContextUrl = options.openingContextUrl;
    } else if (options.saveCurrentContext) {
      openingContextUrl = this.router.url;
    }

    const resultOpeningContextUrl = this.modifyUrl(
      openingContextUrl,
      {
        // remove: {
        //   queryParams: [ REGISTRATION_CONFIRMATION_KEY_PARAM_NAME, RESTORATION_CONFIRMATION_KEY_PARAM_NAME ],
        // },
      }
    );

    return this.router.navigate(
      ROUTES_PATHS.login,
      {
        replaceUrl: isRedirect,
        queryParams: {
          [OPENING_CONTEXT_PARAM_NAME]: resultOpeningContextUrl.url.toString(),
          [REFERRAL_URL_PARAM_NAME]: options.referral,
        },
      }
    );
  }

  navigateToRegistrationStart(
    isRedirect: boolean = false,
  ) {
    return this.router.navigate(
      ROUTES_PATHS.registrationStart,
      {
        replaceUrl: isRedirect,
        queryParams: this.getCurrentRouterQueryParams(),
      }
    );
  }

  navigateToRegistrationStarted(
    isRedirect: boolean = false,
  ) {
    return this.router.navigate(
      ROUTES_PATHS.registrationStarted,
      {
        replaceUrl: isRedirect,
        queryParams: this.getCurrentRouterQueryParams(),
      }
    );
  }

  navigateToRestorationStart(
    isRedirect: boolean = false
  ) {
    return this.router.navigate(
      ROUTES_PATHS.restorationStart,
      {
        replaceUrl: isRedirect,
        queryParams: this.getCurrentRouterQueryParams(),
      }
    );
  }

  navigateToRestorationStarted(
    isRedirect: boolean = false
  ) {
    return this.router.navigate(
      ROUTES_PATHS.restorationStarted,
      {
        replaceUrl: isRedirect,
        queryParams: this.getCurrentRouterQueryParams(),
      }
    );
  }

  navigateToUserPasswordRestorationConfirmation(
    isRedirect: boolean = false,
  ) {
    return this.router.navigate(
      ROUTES_PATHS.userPasswordRestorationConfirmation,
      {
        replaceUrl: isRedirect,
        queryParams: this.getCurrentRouterQueryParams(
          [ LANGUAGE_URL_PARAM_NAME ],
        ),
      }
    );
  }

  navigateToUserPasswordRestorationStarted(
    isRedirect: boolean = false,
  ) {
    return this.router.navigate(
      ROUTES_PATHS.userPasswordRestorationStarted,
      {
        replaceUrl: isRedirect,
        queryParams: this.getCurrentRouterQueryParams(URL_PARAMS_WHICH_SHOULD_BE_REMOVING_FROM_AUTH_LINKS),
      }
    );
  }

  navigateToFirstTimeUserProfileFilling(
    isRedirect: boolean = false
  ) {
    return this.router.navigate(
      ROUTES_PATHS.userProfileFirstTimeFilling,
      {
        replaceUrl: isRedirect,
        queryParams: this.getCurrentRouterQueryParams(URL_PARAMS_WHICH_SHOULD_BE_REMOVING_FROM_AUTH_LINKS),
      }
    );
  }

  navigateToUserPasswordSetting(
    isRedirect: boolean = false,
  ) {
    return this.router.navigate(
      ROUTES_PATHS.userPasswordSetting,
      {
        replaceUrl: isRedirect,
        queryParams: this.getCurrentRouterQueryParams(URL_PARAMS_WHICH_SHOULD_BE_REMOVING_FROM_AUTH_LINKS),
      }
    );
  }

  navigateToOAuthRegistration(
    isRedirect: boolean = false,
  ) {
    return this.router.navigate(
      ROUTES_PATHS.oauthRegistration,
      {
        replaceUrl: isRedirect,
      }
    );
  }

  extractReferralFromCurrentUrl(): string|null {
    const queryParams = this.getCurrentRouterQueryParams();
    const referral = queryParams[REFERRAL_URL_PARAM_NAME];
    return referral || null;
  }

  extractFromParamFromCurrentUrl(): string|null {
    const queryParams = this.getCurrentRouterQueryParams();
    const from = queryParams[FROM_PARAM_NAME];
    return from || null;
  }

  removeReferralFromCurrentUrl() {
    const resultOfUrlWithoutReferralModification = this.modifyUrl(
      this.router.url,
      {
        remove: {
          queryParams: [REFERRAL_URL_PARAM_NAME],
        },
      },
    );
    return this.router.navigateByUrl(
      resultOfUrlWithoutReferralModification.url.toString(),
      {
        replaceUrl: true,
      }
    );
  }

  navigateToTicketsOfferAcceptanceStart(
    isRedirect: boolean,
    options: {
      token: string,
    }
  ) {
    return this.router.navigate(
      ROUTES_PATHS.ticketsOfferAcceptanceStart,
      {
        replaceUrl: isRedirect,
        queryParams: {
          [TICKET_ACCEPTANCE_TOKEN_PARAM_NAME]: options.token,
        },
      },
    );
  }

  getLinkToTicketOfferConfirmation(
    options: {
      ticketOfferId: number,
    },
  ): NavigationRouterLink {
    return createNavigationLink(
      DYNAMIC_ROUTES_PATHS.ticketOfferConfirmation(options.ticketOfferId),
    );
  }

  navigateToTicketOfferConfirmation(
    isRedirect: boolean,
    options: {
      ticketOfferId: number,
    }
  ) {
    return this.router.navigate(
      DYNAMIC_ROUTES_PATHS.ticketOfferConfirmation(options.ticketOfferId),
      {
        replaceUrl: isRedirect,
      },
    );
  }

  extractRegistrationConfirmationKey() {
    const queryParams = this.getCurrentRouterQueryParams();
    const registrationConfirmationKey = queryParams[REGISTRATION_CONFIRMATION_KEY_PARAM_NAME];
    return registrationConfirmationKey || null;
  }

  extractRestorationConfirmationKey() {
    const queryParams = this.getCurrentRouterQueryParams();
    const restorationConfirmationKey = queryParams[RESTORATION_CONFIRMATION_KEY_PARAM_NAME];
    return restorationConfirmationKey || null;
  }

  extractTicketAcceptanceToken() {
    const queryParams = this.getCurrentRouterQueryParams();
    const ticketAcceptanceToken = queryParams[TICKET_ACCEPTANCE_TOKEN_PARAM_NAME];
    return ticketAcceptanceToken || null;
  }

  extractLocaleParameter(): Locale {
    const parsedUrl = this.getParsedUrl();
    const languageParameterValue = parsedUrl.searchParams.get(LANGUAGE_URL_PARAM_NAME);
    return resolveLocaleByString(languageParameterValue);
  }

  extractEmailConfirmationToken(): { emailConfirmationToken: string, from: string } {
    const queryParams = this.getCurrentRouterQueryParams();
    const emailConfirmationToken = queryParams[EMAIL_CONFIRMATION_TOKEN_PARAM_NAME];
    const from = queryParams[OPENING_CONTEXT_PARAM_NAME];

    return {
      emailConfirmationToken,
      from
    };
  }
}

