import { ProtectedSubjectWrapper } from '@ui/jug-ui';
import { CurrentUserDataModel } from './current-user.data-model';
import { UnionDataLayerErrors } from '../../services/DataLayerErrorCode';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { UserRole } from './UserRole';
import { map } from 'rxjs/operators';
import { UserBoundCompanyAPIModel } from '../../services/api/models/user/CurrentUserProfileAPIModel';
import { FetchingDataStateModelForRxWithFlags } from '@ui/fetching-data-state';


export interface AllUserRoles {
  userRoles: UserRole[]
  userCompaniesRoles: UserBoundCompanyAPIModel[],
}


function resolveRoles(currentUserDataModel: CurrentUserDataModel) {
  return currentUserDataModel
    ? [...currentUserDataModel.roles]
    : [];
}

function resolveCompaniesRoles(currentUserDataModel: CurrentUserDataModel) {
  return currentUserDataModel
    ? [...currentUserDataModel.companies]
    : [];
}


export class CurrentUserModel {

  hasConfirmationRequest = false;

  authCheckingDataState = new FetchingDataStateModelForRxWithFlags();
  authCheckingErrors = new ProtectedSubjectWrapper<UnionDataLayerErrors>(null);

  isLoggedIn = new ProtectedSubjectWrapper(false);

  authorizationDataState = new FetchingDataStateModelForRxWithFlags();
  authorizationErrors = new ProtectedSubjectWrapper<UnionDataLayerErrors>(null);

  isLoginAfterRegistration = new ProtectedSubjectWrapper(false);

  cleanAuthorizationTryingData() {
    this.authorizationDataState.reset();
    this.authorizationErrors.set(null);
  }

  userDataState = new FetchingDataStateModelForRxWithFlags();
  private userSubject$ = new BehaviorSubject<CurrentUserDataModel>(null);
  user = new ProtectedSubjectWrapper<CurrentUserDataModel>(null, this.userSubject$);
  userDataErrors = new ProtectedSubjectWrapper<UnionDataLayerErrors>(null);

  markAsLoggedIn(
    currentUser: CurrentUserDataModel,
    isLoginAfterRegistration: boolean = false,
  ) {
    this.authCheckingDataState.success();
    this.authCheckingErrors.set(null);

    this.isLoggedIn.set(true);

    this.authorizationDataState.success();
    this.authorizationErrors.set(null);

    this.user.set(currentUser);
    this.userDataState.success();
    this.userDataErrors.set(null);

    this.isLoginAfterRegistration.set(isLoginAfterRegistration);
  }

  logoutDataState = new FetchingDataStateModelForRxWithFlags();
  logoutErrors =  new ProtectedSubjectWrapper<UnionDataLayerErrors>(null);

  markAsLoggedOut() {
    this.isLoggedIn.set(false);

    this.authorizationDataState.reset();
    this.authorizationErrors.set(null);

    this.user.set(null);
    this.userDataState.reset();
    this.userDataErrors.set(null);

    this.logoutDataState.reset();
    this.logoutErrors.set(null);

    this.oneTimeTokenToSetNewPassword.set(null);
  }

  oneTimeTokenToSetNewPassword = new ProtectedSubjectWrapper<string>(null);

  getRoles(): UserRole[] {
    const currentUser = this.userSubject$.getValue();
    return resolveRoles(currentUser);
  }

  getCompaniesRoles(): UserBoundCompanyAPIModel[] {
    const currentUser = this.userSubject$.getValue();
    return resolveCompaniesRoles(currentUser);
  }

  getBoundCompanyBy(
    companyId$: Observable<number>,
  ): Observable<UserBoundCompanyAPIModel> {
    return combineLatest([
      this.user.stream$,
      companyId$,
    ]).pipe(
      map(([userDataModel, companyId]) => {
        if (userDataModel && companyId) {
          const company = userDataModel.companies.find(company => company.id === companyId);
          if (company) {
            return company;
          }
        }
        return null;
      }),
    );
  }

  private userRolesSubject$ = new BehaviorSubject<UserRole[]>([]);
  private companiesRolesSubject$ = new BehaviorSubject<UserBoundCompanyAPIModel[]>([]);

  roles$: Observable<AllUserRoles> = combineLatest([
    this.userRolesSubject$,
    this.companiesRolesSubject$,
  ]).pipe(
    map(([userRoles, userCompaniesRoles]) => ({
      userRoles,
      userCompaniesRoles,
    })),
  );

  constructor() {
    this.userSubject$
      .subscribe(
        currentUser => {
          this.userRolesSubject$.next(resolveRoles(currentUser));
          this.companiesRolesSubject$.next(resolveCompaniesRoles(currentUser));
        }
      );
  }

}
