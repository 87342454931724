
<button
  class="target"
  [attr.aria-label]="runAreaLabel"
  (click)="onRun.emit()"
></button>

<jc-action
  class="close-action"
  (action)="onClose.emit()"
  [targetAriaLabel]="'notifications.close' | translate"
>
  <jc-cross-icon></jc-cross-icon>
</jc-action>


<div
  class="title"
  *ngIf="_title"
>
  <ng-template
    [ngTemplateOutlet]="_title.templateRef"
  >
  </ng-template>
</div>

<div class="content">
  <ng-content></ng-content>
</div>

<div
  class="actions"
  *ngIf="_actions"
>
  <ng-template
    [ngTemplateOutlet]="_actions.templateRef"
  >
  </ng-template>
</div>


