export const environment = {
  production: true,
  api: {
    url: 'https://lk-dev.jugru.org/api/v1/command',
    useDevInterceptor: false,
  },
  analyticRing: {
    serviceUrl: 'https://dev-ringostar.jugru.team/api/v2/collector/collect',
  },
  clientSideLogging: {
    serviceUrl: 'https://ringologo.jugru.team/',
  },

  configuration: {
    links: {
      outsideCalendarRu: 'https://jugru.org/#schedule',
      outsideCalendarEn: 'https://jugru.org/en/#schedule',

      instructionLinkRu: 'https://lk-instructions.jugru.org/',
      instructionLinkEn: 'https://lk-instructions.jugru.org/',

      contactUsLinkRu: 'support@jugru.team ',
      contactUsLinkEn: 'support@jugru.team',

      bugReportLinkRu: 'support@jugru.team',
      bugReportLinkEn: 'support@jugru.team',

      termsOfUseRu: 'https://jugru.org/terms_of_uses.html',
      termsOfUseEn: 'https://jugru.org/en/terms_of_uses/',

      termsOfUseServiceAgreementRu: 'https://jugru.org/',
      termsOfUseServiceAgreementEn: 'https://jugru.org/',

      privacyPolicyRu: 'https://jugru.org/privacy_policy',
      privacyPolicyEn: 'https://jugru.org/en/privacy_policy',

      cookiePolicyLinkRu: 'https://jugru.org/privacy_policy#7.1.5',
      cookiePolicyLinkEn: 'https://jugru.org/en/privacy_policy#7.1.5',

      distributionTicketsMessageLinkRu: 'https://jugru.org/employees/#conferences',
      distributionTicketsMessageLinkEn: 'https://jugru.org/en/employees/#conferences',

      termsOfCoCLinkRu: 'https://jugru.org/coc/',
      termsOfCoCLinkEn: 'https://jugru.org/en/coc/',

      termsOfPhotoLinkRu: 'https://jugru.org/public_policy/',
      termsOfPhotoLinkEn: 'https://jugru.org/en/public_policy/',

      termsOfDiversityLinkRu: 'https://jugru.org/',
      termsOfDiversityLinkEn: 'https://jugru.org/',

      oAuth2GoogleLink: '/api/v1/oauth2/authorization/google',
      oAuth2YandexLink: '/api/v1/oauth2/authorization/yandex',
      oAuth2VKIDLink: '/api/v1/oauth2/authorization/vkid',

      speakersSrmLink: 'https://speakers-dev.jugru.org',
    },
  },

  integrationTesting: {
    isEnabled: true,
  }

};
