
<div class="content">

  <a
    class="site-logo"
    [routerLink]="['/']"
    [title]="'header.logoAlt' | translate"
  >
    <img
      class="site-logo_img"
      src="/assets/img/logo@2x.png"
      [alt]="'header.logoAlt' | translate"
    />
  </a>

  <div class="main">
    <div class="contact-us">
      <span class="contact-us-accent">{{ 'footer.contactUs.needHelp' | translate }}</span>
      {{ 'footer.contactUs.contactUs' | translate }}
      <a
        class="contact-us-link"
        [href]="'mailto:' + ('fromData' | translate:configurationService.getContactUsLink()) + '?subject=Обращение из личного кабинета'"
      >
        {{ 'fromData' | translate:configurationService.getContactUsLink() }}
      </a>
    </div>

    <div class="rights">
      <a
        class="instruction-link"
        target="_blank"
        [href]="'fromData' | translate:configurationService.getInstructionLink()"
      >
        {{ 'general.instruction' | translate }}
      </a>
      {{ 'footer.rights' | translate }} {{ date | jcCustomDateFormat:'YYYY' }}
    </div>
  </div>

</div>
