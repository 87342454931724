import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { APIResponseStatus } from './APIResponseStatus';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { APIResponse } from './APIResponse';
import { APIErrorCode } from './APIErrorCode';
import { environment } from '../../../../environments/environment';
import { migrationAPITransformer } from './dev/migrationTransformer';
import { API_ERRORS_INTERCEPTOR_TOKEN, APIErrorsHandlerInterceptor } from './APIErrorsHandlerInterceptor';
import {
  APICommandsMapping,
  APICommandRequestsMapping,
  APICommandResponsesMapping
} from './commands/APICommandsMapping';


@Injectable()
export class ApiService {

  private _apiErrorsInterceptor: APIErrorsHandlerInterceptor;

  private get apiErrorsInterceptor(): APIErrorsHandlerInterceptor {
    if (!this._apiErrorsInterceptor) {
      this._apiErrorsInterceptor = this.injector.get(API_ERRORS_INTERCEPTOR_TOKEN);
    }
    return this._apiErrorsInterceptor;
  }

  constructor(
    private http: HttpClient,
    private injector: Injector,
  ) {

    if (environment.integrationTesting){
      // fix for working with not only one environment
      const debugEndpointsRootUrl = environment.api.url.substr(
        0,
        environment.api.url.indexOf('/api') + 1,
      );

      // @ts-ignore
      window.JUG_LK_GET_REGISTRATION_TOKEN = (email, from) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', `${debugEndpointsRootUrl}restws/v1/service/getVerificationUrlByEmail?email=${email}&from=${from ? encodeURIComponent(from) : '%2F'}`);
        xhr.addEventListener('readystatechange', e => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              console.log(xhr.responseText);
            } else {
              console.log(e);
            }
          }
        });
        xhr.send();
      };
      // @ts-ignore
      window.JUG_LK_GET_RESTORATION_TOKEN = (email, from) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', `${debugEndpointsRootUrl}restws/v1/service/getAccessRestoreUrlByEmail?email=${email}&from=${from ? encodeURIComponent(from) : '%2F'}`);
        xhr.addEventListener('readystatechange', e => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              console.log(xhr.responseText);
            } else {
              console.log(e);
            }
          }
        });
        xhr.send();
      };
    }

  }

  /**
   * @description Общий метод запроса данных от серверного API
   * @param command
   * @param commandData
   * @return {Observable<APIResponse<APICommandResponsesMapping[K]|null>>} возвращает не Observable<Either<...>>,
   *         т.к. протокол не стабилен и может расширяться
   */
  request<K extends keyof APICommandsMapping>(
    command: K,
    commandData?: APICommandRequestsMapping[K]
  ): Observable<APIResponse<APICommandResponsesMapping[K]|null>> {

    if (!environment.integrationTesting) {
      console.log('[ApiService]: url', environment.api.url);
      console.log('[ApiService]: command', command);
      console.log('[ApiService]: commandData', commandData || {});
    }

    commandData = migrationAPITransformer.transformRequest(command, commandData);

    return this.http
      .post(
        environment.api.url,
        {
          command: command,
          commandData: commandData || {},
        },
        {
          withCredentials: true,
          observe: 'response'
        }
      ).pipe(
        map(response => {
          const headers = {};

          response.headers.keys().forEach(key => {
            headers[key] = response.headers.get(key);
          });

          return  { ...response.body, headers } as object;
        }),
        map(
          (protocolResponse: APIResponse<APICommandResponsesMapping[K]|null>) => {
            if (protocolResponse.status === APIResponseStatus.SUCCESS) {
              protocolResponse.response = migrationAPITransformer.transformResponse(
                command,
                protocolResponse.response,
                commandData
              );
            }

            return protocolResponse;
          }
        ),
        catchError(error => {
          if (!environment.integrationTesting) {
            console.log('[ApiService]: http error', error);
          }
          // TODO [dmitry.makhnev]: add good errors support
          // TODO [dmitry.makhnev]: log error
          return of<APIResponse<null>>({
            status: APIResponseStatus.ERROR,
            response: null,
            errors: [{
              code: APIErrorCode.NETWORK_ERROR,
              message: '',
            }],
          });
        }),
        mergeMap(protocolResponse => this.apiErrorsInterceptor.intercept(protocolResponse)),
        tap(protocolResponse => {
          if (!environment.integrationTesting) {
            console.log('[ApiService]: result', protocolResponse);
          }
        }),
      );
  }

}
