<ng-container *ngIf="(menu$ | async) as menu">

  <ng-container *ngFor="let menuItem of menu.items">

    <ng-container *ngIf="menuItem.subItems.length; else itemAsLink">
      <div
        class="main-menu-item"
      >
        <jc-tooltip-target class="main-menu-submenus-target" [closeOnClick]="true">
          <ng-template jcTooltipSlot>
            <div class="main-menu-submenus">
              <ng-container *ngFor="let subItem of menuItem.subItems">
                <a
                  [routerLink]="subItem.link.link"
                  [queryParams]="subItem.link.queryParams"
                  [routerLinkActiveOptions]="subItem.routeOptions"
                  class="main-menu-submenu-item"
                  routerLinkActive="active"
                >
                  {{ subItem.text ? subItem.text : subItem.key | translate }}
                </a>
              </ng-container>
            </div>
          </ng-template>
        </jc-tooltip-target>
        <div
          class="main-menu-item_main"
          [routerLink]="menuItem.link.link"
          [queryParams]="menuItem.link.queryParams"
          [routerLinkActiveOptions]="menuItem.routeOptions"
          routerLinkActive="active"
        >
          {{ menuItem.text ? menuItem.text : menuItem.key | translate }}
          <app-triangle-icon class="main-menu-item_icon"></app-triangle-icon>
        </div>
      </div>
    </ng-container>

    <ng-template #itemAsLink>
      <div
        class="main-menu-item"
      >
        <ng-container *ngIf="menuItem.outUrl">
          <a
            class="main-menu-item_main"
            [href]="menuItem.outUrl"
          >
            {{ menuItem.text ? menuItem.text : menuItem.key | translate }}
          </a>
        </ng-container>

        <ng-container *ngIf="!menuItem.outUrl">
          <a
            class="main-menu-item_main"
            [routerLink]="menuItem.link.link"
            [queryParams]="menuItem.link.queryParams"

            [jcActiveRouteChecker]="menuItem.link"
            [activeRouteCheckingOptions]="menuItem.routeOptions"
            [activeRouteExtraLink]="menuItem.extraActiveLink"
            activeRouteClass="active"
          >
            {{ menuItem.text ? menuItem.text : menuItem.key | translate }}
          </a>
        </ng-container>
      </div>
    </ng-template>

  </ng-container>


</ng-container>
