import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../../data/services/auth/auth.service';
import { RouterGuardsViewModel } from '../../models/RouterGuards.view-model';
import { Observable } from 'rxjs';
import {
  tapEitherAnyway,
  tapEither,
  mapEitherToValue,
} from '@ui/jug-ui';
import { NavigationService } from '../../../routing/navigation.service';
import { REGISTRATION_CONFIRMATION_KEY_PARAM_NAME, ROUTES_PATHS } from '../../../routing/navigation.consts';


@Injectable()
export class AuthPartGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private routerGuardsViewModel: RouterGuardsViewModel,
    private navigationService: NavigationService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.routerGuardsViewModel.authPartIsChecking.set(true);

    return this.authService
      .checkAuth()
      .pipe(
        tapEitherAnyway(() => {
          this.routerGuardsViewModel.authPartIsChecking.set(false);
        }),
        tapEither(isLoggedIn => {
          if (isLoggedIn) {
            const currentRouterQueryParams = this.navigationService.getCurrentRouterQueryParams();

            const stateUrl = state.url;
            const isRestorationLink = stateUrl.indexOf(ROUTES_PATHS.restorationConfirmation[0]) === 0;
            const isRegistrationLink = stateUrl.indexOf(ROUTES_PATHS.registrationConfirmation[0]) === 0;

            if (isRestorationLink) {
              this.navigationService.navigateToUserPasswordRestorationConfirmation(true);

            } else if (isRegistrationLink) {
              delete currentRouterQueryParams[REGISTRATION_CONFIRMATION_KEY_PARAM_NAME];
              this.navigationService.navigateToAuthorizedPartWithOpeningContext(true);

            } else {
              this.navigationService.navigateToMain(
                true,
                currentRouterQueryParams,
              );
            }
          }
        }),
        mapEitherToValue(
          true,
          isLoggedIn => !isLoggedIn
        )
      );
  }

}
