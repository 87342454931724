import { NotificationActionHandler } from '../../../../data/services/notifications/NotificationActionHandler';
import { NotificationDataModel } from '../../../../data/models/notifications/notification.data-model';
import { Injectable } from '@angular/core';
import { NavigationService } from '../../../../routing/navigation.service';


@Injectable()
export class SecondTimeRegistrationNotificationActionHandler implements NotificationActionHandler {

  constructor(
    private navigationService: NavigationService,
  ) {}

  handleAction(notification: NotificationDataModel): void {
    this.navigationService.navigateToUserPasswordSetting();
  }

}
