import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuData } from '../common/header/menus/MenuData';
import { NavigationService } from '../../routing/navigation.service';
import { createMenuItemData, MenuItemData} from '../common/header/menus/MenuItemData';
import { PermissionService } from '../../data/services/permission/permission.service';
import {
  BASE_ADMINISTRATION_PART_PERMISSIONS,
  BASE_COMPANY_PART_PAGE_PERMISSIONS,
  BASE_COMPANY_PART_PERMISSIONS_OPTIONS,
} from '../../data/models/user/UserPermissions.constants';
import { CurrentUserModel } from '../../data/models/user/current-user.model';
import { CurrentUserDataModel } from '../../data/models/user/current-user.data-model';
import { AppPartUIViewType } from './AppPartUIViewType';
import { UserRole } from '../../data/models/user/UserRole';
import { ConfigurationService } from '../../data/services/configuration/configuration.service';
import { I18nService, Locale } from '@ui/jug-ui';


@Injectable()
export class MainMenuViewModel {

  menu$ = new BehaviorSubject<MenuData>(null);

  constructor(
    private currentUserMode: CurrentUserModel,
    private navigationService: NavigationService,
    private permissionService: PermissionService,
    private configurationService: ConfigurationService,
    private i18nService: I18nService,
  ) {

    combineLatest([
        currentUserMode.user.stream$,
        permissionService.createPermissionWatcherFor(
          BASE_COMPANY_PART_PAGE_PERMISSIONS,
          BASE_COMPANY_PART_PERMISSIONS_OPTIONS,
        ),
        permissionService.createPermissionWatcherFor(
          BASE_ADMINISTRATION_PART_PERMISSIONS
        ),
      ])
      .pipe(
        map(([
          currentUserDataModel,
          isCompaniesMenuPartIsAvailable,
          isAdministrationMenuPartIsAvailable,
         ]) => this.createMenu(
           currentUserDataModel,
           isCompaniesMenuPartIsAvailable,
           isAdministrationMenuPartIsAvailable,
         )),
      )
      .subscribe(this.menu$);
  }

  private createMenu(
    currentUserDataModel: CurrentUserDataModel,
    isCompaniesMenuPartIsAvailable: boolean,
    isAdministrationMenuPartIsAvailable: boolean,
  ): MenuData {
    const navigationService = this.navigationService;
    const linkToMain = navigationService.getLinkToMain();
    const linkToUserProfileInfo = navigationService.getLinkToUserProfileInfo();

    const items: MenuItemData[] = [];

    items.push(
      createMenuItemData({
        link: navigationService.getLinkToTickets(),
        key: 'mainMenu.items.main',
        routeOptions: {
          exact: true,
        },
        extraActiveLink: {
          link: navigationService.getLinkToTickets(),
        },
      }),
      // createMenuItemData({
      //   link: linkToUserProfileInfo,
      //   key: 'mainMenu.items.profile',
      // }),
    );

    if (currentUserDataModel.roles.includes(UserRole.SRM_USER)) {
      const locale = this.i18nService.getLocale();

      items.push(
        {
          outUrl: this.configurationService.speakersSrmLink + (locale === Locale.EN ? '/en' : ''),
          key: 'mainMenu.items.srm',
          subItems: [],
        } as MenuItemData,
      );
    }

    if (
      currentUserDataModel.roles.includes(UserRole.SRM_COORDINATOR) ||
      currentUserDataModel.roles.includes(UserRole.SRM_EDITOR)
    ) {
      const locale = this.i18nService.getLocale();

      items.push(
        {
          outUrl: this.configurationService.speakersSrmLink + (locale === Locale.EN ? '/en' : '') + '/events',
          key: 'mainMenu.items.srm-events',
          subItems: [],
        } as MenuItemData,
      );
    }

    if (isCompaniesMenuPartIsAvailable) {
      // const companiesSubItems = currentUserDataModel.companies.map(company => {
      //   return createMenuItemData({
      //     link: this.navigationService.getLinkToCompany(company.id.toString(10)),
      //     text: company.brandName,
      //     appUIPartViewType: AppPartUIViewType.COMPANY,
      //   });
      // });

      const linkToCompany = navigationService.getLinkToCompanies();
      items.push(
        createMenuItemData({
          link: linkToCompany,
          key: 'mainMenu.items.companies',
          // subItems: companiesSubItems,
        }),
      );
    }

    if (isAdministrationMenuPartIsAvailable) {
      const linkToAdministration = navigationService.getLinkToAdministration();
      items.push(
        createMenuItemData({
          link: linkToAdministration,
          key: 'mainMenu.items.administration',
        }),
      );
    }

    return {
      items,
    };
  }

}
