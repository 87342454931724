
<ng-container *ngIf="(notifications$ | async).length !== 0">

    <app-notification-card
      *ngFor="let notification of (notifications$ | async)"
      [viewType]="notification.viewType"
      (onClose)="closeNotification(notification)"
      (onRun)="notification.type !== notificationType.GDPR_COOKIE_USAGE ? runNotificationAction(notification) : null"
      [runAreaLabel]="'notifications.' + notification.type + '.label' | translate"
    >

      <ng-container [ngSwitch]="notification.type">

        <ng-container *ngSwitchCase="notificationType.FIST_USER_DATA_FILLING_SUCCESS">
          <ng-template jcHeaderSlot>
            {{ 'notifications.FIST_USER_DATA_FILLING_SUCCESS.title' | translate }}
          </ng-template>
          {{ 'notifications.FIST_USER_DATA_FILLING_SUCCESS.content' | translate }}
          <ng-template jcActionsSlot>
            <app-notification-action
              (action)="runNotificationAction(notification)"
              [targetAriaLabel]="'notifications.FIST_USER_DATA_FILLING_SUCCESS.mainAction' | translate"
            >
              {{ 'notifications.FIST_USER_DATA_FILLING_SUCCESS.mainAction' | translate }}
            </app-notification-action>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="notificationType.REGISTRATION_WAS_COMPLETED_SECOND_TIME">
          <ng-template jcHeaderSlot>
            {{ 'notifications.REGISTRATION_WAS_COMPLETED_SECOND_TIME.title' | translate }}
          </ng-template>
          {{ 'notifications.REGISTRATION_WAS_COMPLETED_SECOND_TIME.content' | translate }}
          <ng-template jcActionsSlot>
            <app-notification-action
              (action)="runNotificationAction(notification)"
              [targetAriaLabel]="'notifications.REGISTRATION_WAS_COMPLETED_SECOND_TIME.mainAction' | translate"
            >
              {{ 'notifications.REGISTRATION_WAS_COMPLETED_SECOND_TIME.mainAction' | translate }}
            </app-notification-action>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="notificationType.USER_PASSWORD_WAS_SET_FOR_RESTORATION">
          <ng-template jcHeaderSlot>
            {{ 'notifications.USER_PASSWORD_WAS_SET_FOR_RESTORATION.title' | translate }}
          </ng-template>
          {{ 'notifications.USER_PASSWORD_WAS_SET_FOR_RESTORATION.content' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="notificationType.USER_PASSWORD_WAS_SET">
          <ng-template jcHeaderSlot>
            {{ 'notifications.USER_PASSWORD_WAS_SET.title' | translate }}
          </ng-template>
          {{ 'notifications.USER_PASSWORD_WAS_SET.content' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="notificationType.USER_PROFILE_WAS_SAVED">
          <ng-template jcHeaderSlot>
            {{ 'notifications.USER_PROFILE_WAS_SAVED.title' | translate }}
          </ng-template>
          {{ 'notifications.USER_PROFILE_WAS_SAVED.content' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="notificationType.USER_PROFILE_NEW_CONTACT_WAS_ADDED">
          <ng-template jcHeaderSlot>
            {{ 'notifications.USER_PROFILE_NEW_CONTACT_WAS_ADDED.title' | translate }}
          </ng-template>
          {{ 'notifications.USER_PROFILE_NEW_CONTACT_WAS_ADDED.content' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="notificationType.USER_PROFILE_CONTACT_WAS_SET_DEFAULT">
          <ng-template jcHeaderSlot>
            {{ 'notifications.USER_PROFILE_CONTACT_WAS_SET_DEFAULT.title' | translate }}
          </ng-template>
          {{ 'notifications.USER_PROFILE_CONTACT_WAS_SET_DEFAULT.content' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="notificationType.USER_PROFILE_CONTACT_WAS_REMOVED">
          <ng-template jcHeaderSlot>
            {{ 'notifications.USER_PROFILE_CONTACT_WAS_REMOVED.title' | translate }}
          </ng-template>
          {{ 'notifications.USER_PROFILE_CONTACT_WAS_REMOVED.content' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="notificationType.USER_PROFILE_CONTACT_WAS_EDITED">
          <ng-template jcHeaderSlot>
            {{ 'notifications.USER_PROFILE_CONTACT_WAS_EDITED.title' | translate }}
          </ng-template>
          {{ 'notifications.USER_PROFILE_CONTACT_WAS_EDITED.content' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="notificationType.GDPR_COOKIE_USAGE">
          <ng-template jcHeaderSlot>
            {{ 'notifications.GDPR_COOKIE_USAGE.title' | translate }}
          </ng-template>
          {{ 'notifications.GDPR_COOKIE_USAGE.content' | translate }} <a [href]="'fromData' | translate:cookieBannerLink" target="_blank">{{ 'notifications.GDPR_COOKIE_USAGE.moreLink' | translate }}</a>

          <ng-template jcActionsSlot>
            <app-notification-action
              (action)="runNotificationAction(notification)"
              [targetAriaLabel]="'notifications.GDPR_COOKIE_USAGE.label' | translate"
            >
              {{ 'notifications.GDPR_COOKIE_USAGE.label' | translate }}
            </app-notification-action>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="notificationType.TICKET_OFFER_WAS_DECLINED">
          <ng-template jcHeaderSlot>
            {{ 'notifications.TICKET_OFFER_WAS_DECLINED.title' | translate }}
          </ng-template>
          {{ 'notifications.TICKET_OFFER_WAS_DECLINED.content' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="notificationType.ONE_TIME_CODE_FOR_CHANGE_PASSWORD_WAS_SENT">
          <ng-template jcHeaderSlot>
            {{ 'login.one_time_code_send_message_title' | translate }}
          </ng-template>
          {{ 'login.one_time_code_send_message' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="notificationType.SEND_ANOTHER_CODE">
          <ng-template jcHeaderSlot>
            {{ 'login.send_another_code_message_title' | translate }}
          </ng-template>
          {{ 'login.send_another_code_message' | translate }}
        </ng-container>

      </ng-container>

    </app-notification-card>

</ng-container>
