import { ProtectedSubjectWrapper } from '@ui/jug-ui';


export class RouterGuardsViewModel {

  authPartIsChecking = new ProtectedSubjectWrapper(false);
  loggedInPartIsChecking = new ProtectedSubjectWrapper(false);

}

