import { Injectable } from '@angular/core';
import { CurrentUserModel } from '../../models/user/current-user.model';
import { UserRole } from '../../models/user/UserRole';
import { map } from 'rxjs/operators';
import { checkPermissionsFor } from './checkPermissionsFor';
import { Observable } from 'rxjs';
import { UserBoundCompanyAPIModel } from '../api/models/user/CurrentUserProfileAPIModel';


export interface UserPermissionPermissionCheckingForCompany {
  companyId: number;
}

export interface UserPermissionPermissionCheckingOptions {
  isForCompanies?: boolean;
  isAny?: boolean;
  companyOptions?: UserPermissionPermissionCheckingForCompany;
}


const DEFAULT_PERMISSIONS_OPTIONS: UserPermissionPermissionCheckingOptions = {
  isAny: false,
  isForCompanies: false,
};


@Injectable()
export class PermissionService {

  constructor(
    private currentUserModel: CurrentUserModel,
  ) {}

  private getCompaniesRoles(
    companyOptions: UserPermissionPermissionCheckingForCompany,
  ): UserRole[] {
    let result: UserRole[];

    let companies: UserBoundCompanyAPIModel[] = this.currentUserModel.getCompaniesRoles();

    if (companyOptions) {
      const foundCompanyOption = companies.find(
        company => company.id === companyOptions.companyId
      );
      companies = foundCompanyOption ? [ foundCompanyOption ] : [];
    }

    result = companies.reduce(
      (roles, company) => roles.concat(company.roles),
      [],
    );

    return result;
  }

  private checkPermissionFor(
    checkingRoles: UserRole[],
    options: UserPermissionPermissionCheckingOptions = DEFAULT_PERMISSIONS_OPTIONS,
  ): boolean {
    const currentRoles = options.isForCompanies === true
      ? this.getCompaniesRoles(options.companyOptions)
      : this.currentUserModel.getRoles();

    return checkPermissionsFor(
      checkingRoles,
      currentRoles,
      options.isAny || false,
    );
  }

  createPermissionWatcherFor(
    checkingRoles: UserRole[],
    checkingOptions?: UserPermissionPermissionCheckingOptions,
  ): Observable<boolean> {
    return this.currentUserModel
      .roles$
      .pipe(
        map(() => this.checkPermissionFor(checkingRoles, checkingOptions)),
      );
  }

  createPermissionWatcherForAnyRolesOfCompany(
    checkingRoles: UserRole[],
    companyId: number,
  ): Observable<boolean> {
    return this.createPermissionWatcherFor(
      checkingRoles,
      {
        isAny: true,
        isForCompanies: true,
        companyOptions: {
          companyId,
        },
      },
    );
  }

}
