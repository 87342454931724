import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuData } from '../MenuData';
import { MainMenuViewModel } from '../../../../models/MainMenu.view-model';
import { CurrentUserDataModel } from '../../../../../data/models/user/current-user.data-model';
import { NavigationService } from '../../../../../routing/navigation.service';
import { NavigationRouterLink } from '@ui/jug-ui';
import { MenuItemData } from '../MenuItemData';

import { createDisplayName } from '../../createDisplayName';


@Component({
  selector: 'app-mobile-main-menu-panel',
  templateUrl: './mobile-main-menu-panel.component.html',
  styleUrls: ['./mobile-main-menu-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMainMenuPanelComponent {

  @Input()
  user: CurrentUserDataModel;

  @HostBinding('class.is-opened')
  isOpenedFlag = false;

  @Input()
  set isOpened(isOpened: boolean) {
    this.isOpenedFlag = isOpened;
    this.openedMenuItem$.next(null);
  };

  openedMenuItem$ = new BehaviorSubject<MenuItemData>(null);

  toggleSubmenu(event: Event, itemFor: MenuItemData) {
    event.stopPropagation();

    const openedMenuItem$ = this.openedMenuItem$;

    openedMenuItem$.next(
      openedMenuItem$.getValue() === itemFor
        ? null
        : itemFor
    );
  }

  @Output()
  logout = new EventEmitter();

  userInfoLink: NavigationRouterLink;

  menu$: Observable<MenuData>;

  constructor(
    private mainMenuViewModel: MainMenuViewModel,
    private navigationService: NavigationService,
  ) {
    this.menu$ = mainMenuViewModel.menu$;
    this.userInfoLink = navigationService.getLinkToUserProfileInfo();
  }

  public get displayName(): string {
    return createDisplayName(
      this.user.nickName,
      this.user.firstName,
      this.user.familyName,
      this.user.email,
    );
  }
}
