
<div
  class="visual"
  [attr.aria-hidden]="isOpened ? 'false' : 'true'"
>
  <div class="content">

    <div
      *ngIf="(menu$ | async) as menu"
      class="auth-menu"
    >
      <a
        *ngFor="let menuItem of menu.items"
        class="auth-menu-item"

        [routerLink]="menuItem.link.link"
        [queryParams]="menuItem.link.queryParams"

        [jcActiveRouteChecker]="menuItem.link"
        [activeRouteCheckingOptions]="menuItem.routeOptions"
        [activeRouteExtraLink]="menuItem.extraActiveLink"
        activeRouteClass="active"
      >
        {{ 'authMenu.items.' + menuItem.key | translate }}
      </a>
    </div>

  </div>
</div>


