/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ui/jug-ui";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./auth-menu.component";
import * as i7 from "../../../../models/AuthMenu.view-model";
var styles_AuthMenuComponent = [i0.styles];
var RenderType_AuthMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthMenuComponent, data: {} });
export { RenderType_AuthMenuComponent as RenderType_AuthMenuComponent };
function View_AuthMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["activeRouteClass", "active"], ["class", "auth-menu-item"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 0, i4.ActiveRouteCheckerDirective, [i2.Router, i1.ElementRef, i1.Renderer2], { jcActiveRouteChecker: [0, "jcActiveRouteChecker"], activeRouteCheckingOptions: [1, "activeRouteCheckingOptions"], activeRouteExtraLink: [2, "activeRouteExtraLink"], activeRouteClass: [3, "activeRouteClass"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = _v.context.$implicit.link.queryParams; var currVal_3 = _v.context.$implicit.link.link; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.link; var currVal_5 = _v.context.$implicit.routeOptions; var currVal_6 = _v.context.$implicit.extraActiveLink; var currVal_7 = "active"; _ck(_v, 2, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_8 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(("authMenu.items." + _v.context.$implicit.key))); _ck(_v, 3, 0, currVal_8); }); }
function View_AuthMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthMenuComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.ngIf.items; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AuthMenuComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_AuthMenuComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.menu$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AuthMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-auth-menu", [], null, null, null, View_AuthMenuComponent_0, RenderType_AuthMenuComponent)), i1.ɵdid(1, 49152, null, 0, i6.AuthMenuComponent, [i7.AuthMenuViewModel], null, null)], null, null); }
var AuthMenuComponentNgFactory = i1.ɵccf("app-auth-menu", i6.AuthMenuComponent, View_AuthMenuComponent_Host_0, {}, {}, []);
export { AuthMenuComponentNgFactory as AuthMenuComponentNgFactory };
