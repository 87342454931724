import { Injectable } from '@angular/core';
import { NotificationsModel } from '../../models/notifications/notifications.model';
import { createNotificationDataModel } from '../../models/notifications/notification.data-model';
import { NotificationViewType } from '../../models/notifications/NotificationViewType';
import { NotificationType } from '../../models/notifications/NotificationType';
import { NotificationActionHandler } from './NotificationActionHandler';
import { ConfigurationService } from '../configuration/configuration.service';


@Injectable()
export class NotificationsService {
  private notificationsActionHandlers = new Map<NotificationType, NotificationActionHandler>();

  constructor(
    private notificationsModel: NotificationsModel,
    private configurationService: ConfigurationService,
  ) {}

  notify(
    type: NotificationType,
    notificationOptions: {
      viewType?: NotificationViewType,
      durationOfShowing?: number,
      showUntilFirstUserInteraction?: boolean,
    } = {}
  ) {
    const notificationDataModel = createNotificationDataModel(
      Date.now(),
      notificationOptions.durationOfShowing || this.configurationService.getDefaultNotificationsDurationTime(),
      type,
      notificationOptions.viewType,
    );

    if (!notificationOptions.showUntilFirstUserInteraction) {
      setTimeout(
        () => {
          this.hideNotification(notificationDataModel.id);
        },
        notificationDataModel.durationOfShowing,
      );
    }

    this.notificationsModel.addNotification(notificationDataModel);
  }

  hideNotification(notificationId: string) {
    this.notificationsModel.removeNotification(notificationId);
  }

  closeNotification(notificationId: string) {
    this.notificationsModel.removeNotification(notificationId);
    return this;
  }

  runNotificationAction(notificationId: string) {
    const notificationDataModel = this.notificationsModel.getNotificationById(notificationId);
    if (notificationDataModel) {
      const notificationActionHandler = this.notificationsActionHandlers.get(notificationDataModel.type);
      if (notificationActionHandler) {
        notificationActionHandler.handleAction(notificationDataModel);
      }
      this.notificationsModel.removeNotification(notificationId);
    }
    return this;
  }

  registerNotificationActionHandler(
    notificationType: NotificationType,
    actionHandler: NotificationActionHandler,
  ) {
    // TODO [dmitry.makhnev]: think about already registered
    this.notificationsActionHandlers.set(notificationType, actionHandler);
    return this;
  }


}
