import { NotificationActionHandler } from '../../../../data/services/notifications/NotificationActionHandler';
import { Injectable } from '@angular/core';
import { NavigationService } from '../../../../routing/navigation.service';
import { NotificationDataModel } from '../../../../data/models/notifications/notification.data-model';


@Injectable()
export class FirstTimeRegistrationNotificationActionHandler implements NotificationActionHandler {

  constructor(
    private navigationService: NavigationService,
  ) {}

  handleAction(notification: NotificationDataModel): void {
    this.navigationService.navigateToEditingBaseUserProfile();
  }

}

