import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../../data/services/auth/auth.service';
import { NavigationService } from '../../../routing/navigation.service';
import { RouterGuardsViewModel } from '../../models/RouterGuards.view-model';
import { mapEitherToValue, tapEither, tapEitherAnyway } from '@ui/jug-ui';


const OAUTH_REGISTRATION_PATH = '/oauth/registration';
const OAUTH_ERROR_PATH = '/oauth/error';

@Injectable()
export class OAuthPartGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private routerGuardsViewModel: RouterGuardsViewModel,
    private navigationService: NavigationService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.routerGuardsViewModel.authPartIsChecking.set(true);

    return this.authService
      .checkAuth()
      .pipe(
        tapEitherAnyway(() => {
          this.routerGuardsViewModel.authPartIsChecking.set(false);
        }),
        tapEither(isLoggedIn => {
          if (isLoggedIn) {
            const currentRouterQueryParams = this.navigationService.getCurrentRouterQueryParams();

            if (
              state.url !== OAUTH_REGISTRATION_PATH &&
              !state.url.startsWith(OAUTH_ERROR_PATH)
            ) {
              this.navigationService.navigateToMain(
                true,
                currentRouterQueryParams,
              );
            }
          }
        }),
        mapEitherToValue(
          () => {
            if (state.url === OAUTH_REGISTRATION_PATH) {
              const currentRouterQueryParams = this.navigationService.getCurrentRouterQueryParams();

              this.navigationService.navigateToMain(
                true,
                currentRouterQueryParams,
              );
            }

            return true;
          },
          isLoggedIn => {
            if (
              state.url === OAUTH_REGISTRATION_PATH ||
              state.url.startsWith(OAUTH_ERROR_PATH)
            ) {
              return true;
            }

            return !isLoggedIn;
          }
        )
      );
  }

}
