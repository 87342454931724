import { Injectable } from '@angular/core';
import { clientSideLoggingInstance } from './ClinetSideLoggingInstance';

@Injectable()
export class ClientSideLoggingProvider {

  getClientSideLogging() {
    return clientSideLoggingInstance;
  }

}
