import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NotificationsModel } from '../../../data/models/notifications/notifications.model';
import { NotificationsService } from '../../../data/services/notifications/notifications.service';
import { NotificationDataModel } from '../../../data/models/notifications/notification.data-model';
import { Observable } from 'rxjs';
import { NotificationType } from '../../../data/models/notifications/NotificationType';
import { SecondTimeRegistrationNotificationActionHandler } from './action-handlers/SecondTimeRegistrationNotificationActionHandler';
import { FirstTimeRegistrationNotificationActionHandler } from './action-handlers/FirstTimeRegistrationNotificationActionHandler';
import { GdprService } from '../../../data/services/gdpr/gdpr.service';
import { ConfigurationService } from '../../../data/services/configuration/configuration.service';
import {
  LocalizableModelField,
  tapEither,
} from '@ui/jug-ui';
import { GDPRCookieNotificationActionHandler } from './action-handlers/GDPRCookieNotificationActionHandler';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit {

  notifications$: Observable<NotificationDataModel[]>;
  notificationType = NotificationType;

  cookieBannerLink: LocalizableModelField;


  constructor(
    private notificationsModel: NotificationsModel,
    private notificationsService: NotificationsService,
    private gdprService: GdprService,
    private configurationService: ConfigurationService,
    private secondTimeRegistrationNotificationActionHandler: SecondTimeRegistrationNotificationActionHandler,
    private firstTimeRegistrationNotificationActionHandler: FirstTimeRegistrationNotificationActionHandler,
    private gdprCookieNotificationActionHandler: GDPRCookieNotificationActionHandler,
  ) {
    this.notifications$ = notificationsModel.list.stream$;

    notificationsService.registerNotificationActionHandler(
      NotificationType.REGISTRATION_WAS_COMPLETED_SECOND_TIME,
      secondTimeRegistrationNotificationActionHandler,
    );
    notificationsService.registerNotificationActionHandler(
      NotificationType.FIST_USER_DATA_FILLING_SUCCESS,
      firstTimeRegistrationNotificationActionHandler,
    );
    notificationsService.registerNotificationActionHandler(
      NotificationType.GDPR_COOKIE_USAGE,
      gdprCookieNotificationActionHandler,
    );

    this.cookieBannerLink = configurationService.getCookieBannerLink();

    // setTimeout(
    //   () => {
    //     this.notificationsService.notify(NotificationType.FIST_USER_DATA_FILLING_SUCCESS, { durationOfShowing: 1000000 });
    //     setTimeout(
    //       () => {
    //         this.notificationsService.notify(NotificationType.REGISTRATION_WAS_COMPLETED_SECOND_TIME, { durationOfShowing: 1000000 });
    //         this.notificationsService.notify(NotificationType.USER_PASSWORD_WAS_SET_FOR_RESTORATION, { durationOfShowing: 1000000 });
    //         this.notificationsService.notify(NotificationType.USER_PROFILE_WAS_SAVED, { durationOfShowing: 1000000 });
    //       },
    //       500
    //     )
    //   },
    //   500
    // );
  }

  ngOnInit() {
    this.gdprService
      .doWeNeedToShow()
      .pipe(
        tapEither(needToShow => {
          if (needToShow) {
            this.notificationsService.notify(
              NotificationType.GDPR_COOKIE_USAGE,
              {
                showUntilFirstUserInteraction: true,
              }
            );
          }
        })
      )
      .subscribe();
  }


  closeNotification(notification: NotificationDataModel) {
    this.notificationsService.closeNotification(notification.id);
  }

  runNotificationAction(notification: NotificationDataModel) {
    this.notificationsService.runNotificationAction(notification.id);
  }


}
