import { APIResponse } from './APIResponse';
import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';


export interface APIErrorsHandlerInterceptor {
  intercept<K>(response: APIResponse<K>): Observable<APIResponse<K>>
}


export const API_ERRORS_INTERCEPTOR_TOKEN = new InjectionToken<APIErrorsHandlerInterceptor>('APIErrorsHandlerInterceptor');
