/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found-route.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./not-found-route.component";
var styles_NotFoundRouteComponent = [i0.styles];
var RenderType_NotFoundRouteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundRouteComponent, data: {} });
export { RenderType_NotFoundRouteComponent as RenderType_NotFoundRouteComponent };
export function View_NotFoundRouteComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" not-found-route works!\n"]))], null, null); }
export function View_NotFoundRouteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-not-found-route", [], null, null, null, View_NotFoundRouteComponent_0, RenderType_NotFoundRouteComponent)), i1.ɵdid(1, 49152, null, 0, i2.NotFoundRouteComponent, [], null, null)], null, null); }
var NotFoundRouteComponentNgFactory = i1.ɵccf("app-not-found-route", i2.NotFoundRouteComponent, View_NotFoundRouteComponent_Host_0, {}, {}, []);
export { NotFoundRouteComponentNgFactory as NotFoundRouteComponentNgFactory };
