/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-profile-role-representation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@ui/jug-ui/ui-jug-ui.ngfactory";
import * as i4 from "@ui/jug-ui";
import * as i5 from "@angular/common";
import * as i6 from "./user-profile-role-representation.component";
var styles_UserProfileRoleRepresentationComponent = [i0.styles];
var RenderType_UserProfileRoleRepresentationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserProfileRoleRepresentationComponent, data: {} });
export { RenderType_UserProfileRoleRepresentationComponent as RenderType_UserProfileRoleRepresentationComponent };
function View_UserProfileRoleRepresentationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("user-profile-role.default-main-page.title-no")); _ck(_v, 1, 0, currVal_0); }); }
function View_UserProfileRoleRepresentationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " \u2013 ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("user-profile-role.default-main-page.title-yes")); var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform(("user-profile-role.default-main-page." + _co.selectedRole))); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_UserProfileRoleRepresentationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "role-check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "jc-input-checkbox", [], [[2, "is-valid", null], [2, "is-invalid", null], [2, "able-to-show-valid-state", null], [2, "able-to-show-invalid-state", null]], [[null, "onValueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onValueChange" === en)) {
        var pd_0 = (_co.onRoleChecked(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_InputCheckboxComponent_0, i3.RenderType_InputCheckboxComponent)), i1.ɵprd(6144, null, i4.AbstractCustomInputComponent, null, [i4.InputCheckboxComponent]), i1.ɵdid(4, 49152, null, 0, i4.InputCheckboxComponent, [], { inputId: [0, "inputId"], disabled: [1, "disabled"], isChecked: [2, "isChecked"] }, { onValueChange: "onValueChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "label", [], [[8, "htmlFor", 0], [8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_4 = i1.ɵinlineInterpolate(1, "", ("check_" + _v.context.$implicit), ""); var currVal_5 = (_co.selectedRole === _v.context.$implicit); var currVal_6 = (_co.selectedRole === _v.context.$implicit); _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).isValid; var currVal_1 = i1.ɵnov(_v, 4).isInvalid; var currVal_2 = i1.ɵnov(_v, 4).ableToShowValidState; var currVal_3 = i1.ɵnov(_v, 4).ableToShowInvalidState; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_7 = ("check_" + _v.context.$implicit); var currVal_8 = i1.ɵinlineInterpolate(1, "", (("role " + "role_") + _v.context.$implicit), ""); _ck(_v, 5, 0, currVal_7, currVal_8); var currVal_9 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(("user-profile-role.default-main-page." + _v.context.$implicit))); _ck(_v, 7, 0, currVal_9); }); }
export function View_UserProfileRoleRepresentationComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["details", 1]], null, 9, "details", [], null, [[null, "toggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("toggle" === en)) {
        var pd_0 = (_co.onToggleDetails(i1.ɵnov(_v, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "summary", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserProfileRoleRepresentationComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserProfileRoleRepresentationComponent_2)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "roles"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserProfileRoleRepresentationComponent_3)), i1.ɵdid(10, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.selectedRole; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.selectedRole; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.filterRoles(_co.user.roles); _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", ("content " + (_co.detailsOpened ? "" : ("role_" + _co.selectedRole))), ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_UserProfileRoleRepresentationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-profile-role-representation", [], null, null, null, View_UserProfileRoleRepresentationComponent_0, RenderType_UserProfileRoleRepresentationComponent)), i1.ɵdid(1, 114688, null, 0, i6.UserProfileRoleRepresentationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserProfileRoleRepresentationComponentNgFactory = i1.ɵccf("app-user-profile-role-representation", i6.UserProfileRoleRepresentationComponent, View_UserProfileRoleRepresentationComponent_Host_0, { user: "user" }, {}, []);
export { UserProfileRoleRepresentationComponentNgFactory as UserProfileRoleRepresentationComponentNgFactory };
