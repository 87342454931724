<header [ngClass]="{ 'header': true, '__logged-in': isLoggedIn$ | async }">
  <div class="main">
    <div class="content">

      <div class="mobile-navigation-w">
        <ng-container
          [jcPortalOutlet]="'mobile-navigation'"
          (componentWasMounted)="onMobileNavigationMounted()"
          (componentWasUnmounted)="onMobileNavigationUnmounted()"
        ></ng-container>
      </div>

      <a
        class="site-logo"
        [routerLink]="linkToMain.link"
        [title]="'header.logoAlt' | translate"
      >
        <img
          class="site-logo_img"
          src="/assets/img/logo@2x.png"
          [alt]="'header.logoAlt' | translate"
        />
      </a>

      <app-language-switcher></app-language-switcher>

      <ng-container *ngIf="isLoggedIn$ | async; else notAuthPart">
        <div class="authorized-user-part">

          <app-main-menu></app-main-menu>

          <div class="authorized-user-part_extra">
            <div class="displayName">
              <a
                class="user-email-link"
                [routerLink]="userInfoLink.link"
                [queryParams]="userInfoLink.queryParams"
              >
                {{ displayName | async }}
              </a>
            </div>

            <jc-action
              class="exit"
              (action)="logout()"
              [targetAriaLabel]="'header.logoutAction' | translate"
            >
              <jc-exit-icon></jc-exit-icon>
            </jc-action>
          </div>

        </div>
      </ng-container>

      <ng-template #notAuthPart>
        <app-auth-menu></app-auth-menu>
      </ng-template>

      <jc-action
        class="bug-report-action"
        [href]=" 'mailto:' + ('fromData' | translate:configurationService.getBugReportLink()) + '?subject=Обращение из личного кабинета'"
        [targetAriaLabel]=" 'header.bugReport' | translate "
      >
        <jc-bug-report-icon></jc-bug-report-icon>
      </jc-action>

      <jc-action
        [ngClass]="['mobile-menu-opener', MOBILE_MENU_ELEMENT]"
        (action)="onMobileMenuToggle()"
      >
        <jc-hamburger-icon [asCross]="mobileMenuOpen$ | async"></jc-hamburger-icon>
      </jc-action>

      <app-notifications class="notifications"></app-notifications>
    </div>
  </div>

  <div class="second-level-menu-part">
    <div class="second-level-menu-w">
      <ng-container
        [jcPortalOutlet]="'second-level-menu'"
        (componentWasMounted)="onSubMenuMounted()"
        (componentWasUnmounted)="onSubMenuUnmounted()"
      >
      </ng-container>
    </div>
  </div>

  <div class="mobile-second-level-menu-part">
    <div class="mobile-second-level-menu-w">
      <ng-container
        [jcPortalOutlet]="'mobile-second-level-navigation'"
        (componentWasMounted)="onMobileSecondLevelNavigationMounted()"
        (componentWasUnmounted)="onMobileSecondLevelNavigationUnmounted()"
      ></ng-container>
    </div>
  </div>
</header>

<div [ngClass]="{ 'mobile-main-menu-overlay': true, 'is-showed': mobileMenuOpen$ | async }"></div>

<app-mobile-main-menu-panel
  *ngIf="isLoggedIn$ | async; else authMobileMenu"
  [isOpened]="mobileMenuOpen$ | async"
  [ngClass]="MOBILE_MENU_ELEMENT"
  [user]="currentUserDataModel$ | async"
  (logout)="logout()"
></app-mobile-main-menu-panel>

<ng-template #authMobileMenu>
  <app-mobile-auth-menu-panel
    [isOpened]="mobileMenuOpen$ | async"
    [ngClass]="MOBILE_MENU_ELEMENT"
  ></app-mobile-auth-menu-panel>
</ng-template>

