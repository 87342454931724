import { UserRole } from '../../models/user/UserRole';


export function checkPermissionsFor(
  checkingRoles: UserRole[],
  currentRoles: UserRole[],
  isAny: boolean = false,
): boolean {
  const iMax = checkingRoles.length;

  if (isAny) {
    if (iMax === 0 && currentRoles.length === 0) {
      return true;
    }

    for (let i = 0; i !== iMax; i += 1) {
      if (currentRoles.includes(checkingRoles[i])) {
        return true;
      }
    }
    return false;

  } else {
    for (let i = 0; i !== iMax; i += 1) {
      if (!currentRoles.includes(checkingRoles[i])) {
        return false;
      }
    }
  }

  return true;
}

