import { NgModule } from '@angular/core';
import { NotificationsComponent } from './notifications.component';
import { NotificationsService } from '../../../data/services/notifications/notifications.service';
import { NotificationsModel } from '../../../data/models/notifications/notifications.model';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationCardComponent } from './card/notification-card.component';
import { SharedModule } from '../../../shared/shared.module';
import { NotificationActionComponent } from './action/notification-action.component';
import { FirstTimeRegistrationNotificationActionHandler } from './action-handlers/FirstTimeRegistrationNotificationActionHandler';
import { SecondTimeRegistrationNotificationActionHandler } from './action-handlers/SecondTimeRegistrationNotificationActionHandler';
import { GDPRCookieNotificationActionHandler } from './action-handlers/GDPRCookieNotificationActionHandler';


@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationCardComponent,
    NotificationActionComponent,
  ],
  imports: [
    SharedModule,
  ],
  exports: [
    NotificationsComponent,
  ],
  providers: [
    NotificationsService,
    NotificationsModel,
    FirstTimeRegistrationNotificationActionHandler,
    SecondTimeRegistrationNotificationActionHandler,
    GDPRCookieNotificationActionHandler,
  ],
})
export class NotificationsModule {

}
