import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-not-found-route',
  templateUrl: './not-found-route.component.html',
  styleUrls: ['./not-found-route.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundRouteComponent {

}
