import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { clientSideLoggingInstance } from './app/data/services/logging/ClinetSideLoggingInstance';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => {
    if (environment.integrationTesting.isEnabled) {
      console.log(err);
    }
    clientSideLoggingInstance.errorLoggingService.logError(err);
  });


if (environment.integrationTesting) {
  // @ts-ignore
  window._JUG_LK_TEST_ERRORS_LOGGING_ = function () {
    setTimeout(() => {
      throw new Error('_JUG_LK_TEST_ERRORS_LOGGING_');
    })
  };
}
