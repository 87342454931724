import { Injectable } from '@angular/core';
import { CurrentUserModel } from '../../models/user/current-user.model';
import { first, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { APICommandName } from '../api/commands/APICommandName';
import { CurrentUserDataModel } from '../../models/user/current-user.data-model';
import { bimapEither, bitapEither, createErrorCodesMapping, Deduplicator, Either, I18nService, mapEither, tapEither } from '@ui/jug-ui';
import { APIErrorCode } from '../api/APIErrorCode';
import { eitherifyAPIServiceResponse } from '../api/eitherifyAPIServiceResponse';
import { ReferralService } from '../referal/referral.service';
import { UnionDataLayerErrors } from '../DataLayerErrorCode';
import { CurrentUserApiModelTransformer } from '../../models/user/current-user.api-model-transformer';
import { AnalyticsClientProvider } from '../analytics/AnalyticsClientProvider';
import { AnalyticsActions } from '../analytics/AnalyticsActions';
import { NavigationService } from '../../../routing/navigation.service';
import { LoginAuthorizationResult } from './LoginAuthorizationResult';
import { LOGIN_API_ERROR_TO_DATA_LAYER_ERRORS_MAP } from './LOGIN_API_ERROR_TO_DATA_LAYER_ERRORS_MAP';
import { APIResponseHeaders } from '../api/APIResponseHeaders';
import { UserRole } from '../../models/user/UserRole';
import {
  getUserProfileRoleRepresentationFromLocaleStorage
} from '../../../ui/components/user-profile-role-representation/user-profile-role-representation.localeSrorage';
import {
  UserRequestContactVerificationAPIResponse
} from '../api/commands/user/contacts/UserRequestContactVerificationAPIResponse';


@Injectable()
export class AuthService {

  constructor(
    private currentUserModel: CurrentUserModel,
    private apiService: ApiService,
    private referralService: ReferralService,
    private currentUserApiModelTransformer: CurrentUserApiModelTransformer,
    private i18nService: I18nService,
    private analyticsClientProvider: AnalyticsClientProvider,
    private navigationService: NavigationService,
  ) {}

  public checkHasConfirmationRequest(): Observable<boolean> {
    return this.apiService
      .request(APICommandName.USER_GET_INFO)
      .pipe(
        map(response => {
          return response.headers.hasOwnProperty(APIResponseHeaders.CONFIRMATION_REQUESTED);
        }),
      );
  }

  private checkLoggedIn(): Observable<Either<boolean, UnionDataLayerErrors>> {
    const currentUserModel = this.currentUserModel;
    currentUserModel.authCheckingDataState.fetch();

    return this.apiService
      .request(APICommandName.USER_GET_INFO)
      .pipe(
        map(response => {
          if (
            response.headers &&
            response.headers.hasOwnProperty(APIResponseHeaders.CONFIRMATION_REQUESTED)
          ) {
            currentUserModel.hasConfirmationRequest = true;
          } else {
            currentUserModel.hasConfirmationRequest = false;
          }

          return response;
        }),
        eitherifyAPIServiceResponse(),
        mapEither(
          apiUserGetInfoResponse => this.currentUserApiModelTransformer.transform(apiUserGetInfoResponse.user),
        ),
        bitapEither(
          currentUserDataModel => {
            if (currentUserModel.hasConfirmationRequest) {
              this.navigationService.navigateToMergeLk();
            }

            this.authorize(currentUserDataModel);
          },
          errors => {
            if (errors[0].code === APIErrorCode.NETWORK_ERROR) {
              currentUserModel.authCheckingDataState.fail();
              currentUserModel.authCheckingErrors.set(errors);
            } else {
              currentUserModel.authCheckingDataState.success();
              currentUserModel.authCheckingErrors.set(null);
            }
          }
        ),
        mapEither(true),
      );
  }

  private checkAuthStatusDeduplication = new Deduplicator(() => {
    const currentUserModel = this.currentUserModel;
    return currentUserModel.authCheckingDataState
      .stateAsFlags$
      .pipe(
        first(),
        switchMap(authCheckingDataState => {
          if (authCheckingDataState.isReady) {
            return currentUserModel
              .isLoggedIn
              .stream$
              .pipe(
                map(isLoggedIn => Either.right<boolean, UnionDataLayerErrors>(isLoggedIn))
              );
          }
          return this.checkLoggedIn();
        }),
      );
  }) ;

  reload(): Observable<Either<boolean, UnionDataLayerErrors>> {
    this.currentUserModel.authCheckingDataState.fetch();

    return this.apiService
      .request(APICommandName.USER_GET_INFO)
      .pipe(
        map(response => {
          if (
            response.headers.hasOwnProperty(APIResponseHeaders.CONFIRMATION_REQUESTED)
          ) {
            this.currentUserModel.hasConfirmationRequest = true;
          } else {
            this.currentUserModel.hasConfirmationRequest = false;
          }

          return response;
        }),
        eitherifyAPIServiceResponse(),
        mapEither(
          apiUserGetInfoResponse => this.currentUserApiModelTransformer.transform(apiUserGetInfoResponse.user),
        ),
        bitapEither(
          currentUserDataModel => this.authorize(currentUserDataModel),
          errors => {
            if (errors[0].code === APIErrorCode.NETWORK_ERROR) {
              this.currentUserModel.authCheckingDataState.fail();
              this.currentUserModel.authCheckingErrors.set(errors);
            } else {
              this.currentUserModel.authCheckingDataState.success();
              this.currentUserModel.authCheckingErrors.set(null);
            }
          }
        ),
        mapEither(true),
      );
  }

  checkAuth(): Observable<Either<boolean, UnionDataLayerErrors>> {
    return this.checkAuthStatusDeduplication.get();
  }

  authorize(
    currentUserDataModel: CurrentUserDataModel,
    isAuthorizedAfterRegistration: boolean = false,
  ) {
    this.currentUserModel.markAsLoggedIn(
      currentUserDataModel,
      isAuthorizedAfterRegistration
    );
    this.i18nService.setLocale(currentUserDataModel.lang);
    this.analyticsClientProvider
      .getSaveAnalyticsService()
      .save({
        action: AnalyticsActions.LK_LOGGED,
        lkid: currentUserDataModel.id.toString(),
      })
      .catch(e => {
        console.log(e);
      });
  }

  login(
    identity: string,
    password: string,
    rememberMe = true,
  ): Observable<Either<LoginAuthorizationResult, UnionDataLayerErrors>> {
    const currentUserModel = this.currentUserModel;

    currentUserModel.authorizationDataState.fetch();

    return this.apiService
      .request(
        APICommandName.LOGIN_WITH_IDENTITY_AND_PASSWORD,
        {
          identity,
          password,
          referral: this.referralService.getReferral(),
          rememberMe,
        }
      )
      .pipe(
        eitherifyAPIServiceResponse(),
        bimapEither(
          apiLoginWithEmailAndPasswordResponse => ({
            user: this.currentUserApiModelTransformer.transform(apiLoginWithEmailAndPasswordResponse.user),
            meta: apiLoginWithEmailAndPasswordResponse.meta || null,
          }),
          createErrorCodesMapping(LOGIN_API_ERROR_TO_DATA_LAYER_ERRORS_MAP),
        ),
        bitapEither(
          loginAuthorizationResult => {
            this.referralService.resetReferral();
            this.authorize(loginAuthorizationResult.user);
          },
          errors => {
            currentUserModel.authorizationDataState.fail();
            currentUserModel.authorizationErrors.set(errors);
          }
        ),
        tapEither(loginAuthorizationResult => {
          if (loginAuthorizationResult && loginAuthorizationResult.meta && loginAuthorizationResult.meta.redirect) {
            window.location.href = loginAuthorizationResult.meta.redirect;
          }
        }),
      );
  }


  logout(): Observable<Either<boolean, UnionDataLayerErrors>> {
    const currentUserModel = this.currentUserModel;

    currentUserModel.logoutDataState.fetch();
    currentUserModel.hasConfirmationRequest = false;

    return this.apiService
      .request(
        APICommandName.LOGOUT
      )
      .pipe(
        eitherifyAPIServiceResponse(),
        bitapEither(
          () => {
            this.doLogout();
          },
          errors => {
            currentUserModel.logoutDataState.fail();
            currentUserModel.logoutErrors.set(errors);
          }
        ),
        mapEither(true),
      );
  }

  doLogout() {
    this.currentUserModel.markAsLoggedOut();
    this.navigationService.navigateToAuthorize(
      true,
      {
        saveCurrentContext: true,
      }
    );
    // force reload for better state cleaning
    // TODO [dmitry.makhnev]: research this problem with colleagues for logout without page reload
    // document.location.reload();

    // todo: goto main;
    document.location.href = '/';
  }

  requestWithOneTimeAccessCode(identity: string, from: string, lang: string): Observable<Either<unknown, UnionDataLayerErrors>> {
    const commandData = {
      identity,
      from,
      lang,
      referral: this.referralService.getReferral(),
    };

    return this.apiService
      .request(
        APICommandName.LOGIN_REQUEST_WITH_ONE_TIME_ACCESS_CODE,
        commandData
      )
      .pipe(
        eitherifyAPIServiceResponse()
      );
  }

  verifyOneTimeAccessCodeAndLogin(identity: string, code: string): Observable<Either<LoginAuthorizationResult, UnionDataLayerErrors>> {
    const commandData = {
      identity,
      secretKey: code
    };

    const currentUserModel = this.currentUserModel;

    currentUserModel.authorizationDataState.fetch();

    return this.apiService
      .request(
        APICommandName.LOGIN_WITH_ONE_TIME_ACCESS_CODE,
        commandData
      )
      .pipe(
        eitherifyAPIServiceResponse(),
        bimapEither(
          apiLoginWithEmailAndPasswordResponse => ({
            user: this.currentUserApiModelTransformer.transform(apiLoginWithEmailAndPasswordResponse.user),
            meta: apiLoginWithEmailAndPasswordResponse.meta || null,
          }),
          createErrorCodesMapping(LOGIN_API_ERROR_TO_DATA_LAYER_ERRORS_MAP),
        ),
        bitapEither(
          loginAuthorizationResult => {
            this.referralService.resetReferral();
            this.authorize(loginAuthorizationResult.user);
          },
          errors => {
            currentUserModel.authorizationDataState.fail();
            currentUserModel.authorizationErrors.set(errors);
          }
        ),
        tapEither(loginAuthorizationResult => {
          if (loginAuthorizationResult && loginAuthorizationResult.meta && loginAuthorizationResult.meta.redirect) {
            window.location.href = loginAuthorizationResult.meta.redirect;
          }
        }),
      );
  }

  sendEmailWithCodeForChangePassword() {
    return this.apiService
      .request(
        APICommandName.USER_REQUEST_CHANGE_PASSWORD,
      )
      .pipe(
        eitherifyAPIServiceResponse(),
        bimapEither(
          () => {
            ///
          },
          () => {
            ///
          },
        ),
      );
  }
}

