

export enum APIErrorCode {
  // client side
  NETWORK_ERROR = 'NETWORK_ERROR',
  QUOTA_EXCEEDED = 'QUOTA_EXCEEDED',

  // server side
  UNKNOWN_COMMAND = 'UNKNOWN_COMMAND',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  ACCESS_DENIED = 'ACCESS_DENIED',
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  PARAMETER_MISSED = 'PARAMETER_MISSED',
  PARAMETER_INVALID = 'PARAMETER_INVALID',

  VERIFICATION_TOKEN_NOT_FOUND = 'VERIFICATION_TOKEN_NOT_FOUND',
  VERIFICATION_TOKEN_EXPIRED = 'VERIFICATION_TOKEN_EXPIRED',
  VERIFICATION_TOKEN_WAS_USED = 'VERIFICATION_TOKEN_WAS_USED',
  VERIFICATION_TOKEN_WAS_ACCEPTED = 'VERIFICATION_TOKEN_WAS_ACCEPTED',

  FORBIDDEN_COMMAND = 'FORBIDDEN_COMMAND',

  ILLEGAL_STATE_ACTION = 'ILLEGAL_STATE_ACTION',

  NO_ACTIVE_USER_FOUND_BY_TOKEN = 'NO_ACTIVE_USER_FOUND_BY_TOKEN',

  DUPLICATE_CONTACT = 'DUPLICATE_CONTACT',
  DUPLICATE_IDENTITY = 'DUPLICATE_IDENTITY',

  ADDITIONAL_CONTACTS_LIMIT_EXCEEDED = 'ADDITIONAL_CONTACTS_LIMIT_EXCEEDED',
  ADDITIONAL_CONTACTS_VERIFICATION_RATE_LIMIT_EXCEEDED = 'ADDITIONAL_CONTACTS_VERIFICATION_RATE_LIMIT_EXCEEDED',

  DUPLICATE_IDENTITY_MERGE_UNSUPPORTED_FOR_SRM_USER = 'DUPLICATE_IDENTITY_MERGE_UNSUPPORTED_FOR_SRM_USER',
  FOREIGN_COUNTRY_PHONE_VERIFICATION_IS_NOT_SUPPORTED = 'FOREIGN_COUNTRY_PHONE_VERIFICATION_IS_NOT_SUPPORTED',

  TOO_MANY_VERIFICATION_TOKEN_VALIDATION_ATTEMPTS = 'TOO_MANY_VERIFICATION_TOKEN_VALIDATION_ATTEMPTS'
}

export enum APIErrorForbiddenCommandExtraCode {
  USER_ALREADY_HAS_INVITE_TO_THE_EVENT_FROM_THE_COMPANY = 'USER_ALREADY_HAS_INVITE_TO_THE_EVENT_FROM_THE_COMPANY',
  EVENT_HAS_BEEN_FINISHED = 'EVENT_HAS_BEEN_FINISHED',
  DUPLICATE_IDENTITY_AND_CONFIRM_MERGE_LK = 'DUPLICATE_IDENTITY_AND_CONFIRM_MERGE_LK',
}
