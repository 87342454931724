import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuData } from '../MenuData';
import { AuthMenuViewModel } from '../../../../models/AuthMenu.view-model';


@Component({
  selector: 'app-mobile-auth-menu-panel',
  templateUrl: './mobile-auth-menu-panel.component.html',
  styleUrls: ['./mobile-auth-menu-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileAuthMenuPanelComponent {

  @Input()
  @HostBinding('class.is-opened')
  isOpened = false;

  menu$: Observable<MenuData>;

  constructor(
    private authMenuViewModel: AuthMenuViewModel,
  ) {
    this.menu$ = authMenuViewModel.menu$;
  }

}
