import { ExtendedNavigationRouterLink, NavigationRouterLink, RouterLinkActiveOptions } from '@ui/jug-ui';
import { AppPartUIViewType } from '../../../models/AppPartUIViewType';


export interface MenuItemData {
  link?: NavigationRouterLink,
  key: string,
  text: string,

  iconName?: string,

  routeOptions: RouterLinkActiveOptions,

  subItems: MenuItemData[],

  appUIPartViewType: AppPartUIViewType,

  extraActiveLink?: ExtendedNavigationRouterLink,

  outUrl?: string;
}


export function createMenuItemData(
  settings: Partial<MenuItemData>,
): MenuItemData {
  return {
    iconName: settings.iconName || null,
    link: settings.link,
    key: settings.key || null,
    text: settings.text || null,
    routeOptions: settings.routeOptions
      ? settings.routeOptions
      : {
        exact: false,
      },
    subItems: settings.subItems || [],
    appUIPartViewType: settings.appUIPartViewType || null,
    extraActiveLink: settings.extraActiveLink
      ? {
        link: settings.extraActiveLink.link,
        routeOptions: settings.extraActiveLink.routeOptions
          ? settings.extraActiveLink.routeOptions
          : {
            exact: true,
          }
      }
      : null,
  };
}
