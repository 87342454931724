import { Injectable } from '@angular/core';
import { AuthorizationMetaAPIModel } from '../api/models/user/AuthorizationMetaAPIModel';


@Injectable()
export class AuthorizationMetaProcessingService {

  public hasRedirectUrl?: boolean | null;
  public redirectUrl?: string | null;

  redirectByMeta(meta: AuthorizationMetaAPIModel|null): boolean {
    if (meta) {
      if (meta.redirect) {
        window.location.replace(meta.redirect);
        return true;
      }
    }
    return false;
  }

}

