import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConfigurationService } from '../../../data/services/configuration/configuration.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

  date = new Date();

  constructor(
    public configurationService: ConfigurationService,
  ) {}

}
