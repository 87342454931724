import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { GuardsCheckStart, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { RouterGuardsViewModel } from './ui/models/RouterGuards.view-model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { NavigationService } from './routing/navigation.service';
import { ReferralService } from './data/services/referal/referral.service';
import { I18nService, checkHoverAvailability } from '@ui/jug-ui';
import { NotificationsService } from './data/services/notifications/notifications.service';
import { AnalyticsClientProvider } from './data/services/analytics/AnalyticsClientProvider';
import { AnalyticsActions } from './data/services/analytics/AnalyticsActions';
import { AuthService } from './data/services/auth/auth.service';
import { ROUTES_PATHS } from './routing/navigation.consts';
import { UserProfileService } from './data/services/user/profile/user-profile.service';
import { CurrentUserDataModel } from './data/models/user/current-user.data-model';
import { UserRole } from './data/models/user/UserRole';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

  isPageLoading$: Observable<boolean>;
  private navigationWasStarted$ = new BehaviorSubject(false);

  private navigationToMerge$ = new BehaviorSubject(false);

  constructor(
    private i18nService: I18nService,
    private router: Router,
    private routerGuardsViewModel: RouterGuardsViewModel,
    private navigationService: NavigationService,
    private referralService: ReferralService,
    private notificationsService: NotificationsService,
    private analyticsClientProvider: AnalyticsClientProvider,
    private authService: AuthService,
    public userProfileService: UserProfileService,
  ) {
    checkHoverAvailability(document.body);

    i18nService.init(navigationService.extractLocaleParameter());
    i18nService.currentLocale$.subscribe(
      value => {
        if (value) {
          document.documentElement.setAttribute('lang', value);
        }
      }
    );

    const guardCheckStartSubscription = router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.extractReferral();
      }
      if (event instanceof GuardsCheckStart) {
        this.navigationWasStarted$.next(true);
      }
      if (event instanceof NavigationEnd) {
        guardCheckStartSubscription.unsubscribe();

        if (
          event.url === `/${ROUTES_PATHS.mergeLk[0]}` &&
          event.urlAfterRedirects === `/${ROUTES_PATHS.mergeLk[0]}`
        ) {
            this.navigationToMerge$.next(true);
        }
      }
    });

    // подумать о событиях роутера
    this.isPageLoading$ = combineLatest([
        this.navigationWasStarted$,
        routerGuardsViewModel.loggedInPartIsChecking.stream$,
        routerGuardsViewModel.authPartIsChecking.stream$,
        this.navigationToMerge$,
      ]).pipe(
        map(([
          navigationWasStarted,
          isLoggedInPartIsChecking,
          isAuthPartIsChecking,
          navigationToMerge,
        ]) => {
          if (navigationToMerge) {
            return false;
          }

          return !navigationWasStarted || isLoggedInPartIsChecking || isAuthPartIsChecking;
        })
      );
  }

  ngOnInit(): void {
    this.analyticsClientProvider
      .getSaveAnalyticsService()
      .save({
        action: AnalyticsActions.LK_STARTED,
      })
      .catch(e => {
        console.log(e);
      });
  }

  extractReferral() {
    const referral = this.navigationService.extractReferralFromCurrentUrl();
    if (referral) {
      this.referralService.setReferral(referral);
    }
  }

  public canShowUserProfileRoleRepresentation(user: CurrentUserDataModel) {
    const companies = user.companies;
    const roles = user.roles || [];

    if (companies) {
      companies.forEach(company => {
        if (
          company.roles &&
          company.roles.includes(UserRole.COMPANY_ADMIN) &&
          !roles.includes(UserRole.COMPANY_ADMIN)
        ) {
          roles.push(UserRole.COMPANY_ADMIN);
        }
      });
    }

    return roles.filter(r => (
      r !== UserRole.ONLINE_ADMIN &&
      r !== UserRole.SRM_USER &&
      r !== UserRole.ONLINE_EDITOR
    )).length > 1;
  }
}
