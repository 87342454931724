import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';
import { ActionsSlotDirective } from '@ui/jug-ui';
import { NotificationViewType } from '../../../../data/models/notifications/NotificationViewType';
import { HeaderSlotDirective } from '@ui/jug-ui';


@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCardComponent {

  @ContentChild(
    HeaderSlotDirective,
    {
      static: false,
    },
  )
  _title: HeaderSlotDirective;

  @ContentChild(
    ActionsSlotDirective,
    {
      static: false,
    },
  )
  _actions: ActionsSlotDirective;

  @Output()
  onClose = new EventEmitter();

  @Input()
  runAreaLabel: string;

  @Output()
  onRun = new EventEmitter();

  @HostBinding('class.__success')
  isSuccessView = false;

  @HostBinding('class.__warning')
  isWarningView = false;

  @HostBinding('class.__error')
  isErrorView = false;

  @Input()
  set viewType(viewType: NotificationViewType) {
    this.isSuccessView = viewType === NotificationViewType.SUCCESS;
    this.isWarningView = viewType === NotificationViewType.WARNING;
    this.isErrorView = viewType === NotificationViewType.ERROR;
  }

}
