/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@ui/jug-ui/ui-jug-ui.ngfactory";
import * as i4 from "@ui/jug-ui";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./notification-card.component";
var styles_NotificationCardComponent = [i0.styles];
var RenderType_NotificationCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationCardComponent, data: {} });
export { RenderType_NotificationCardComponent as RenderType_NotificationCardComponent };
function View_NotificationCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_NotificationCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationCardComponent_2)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._title.templateRef; _ck(_v, 2, 0, currVal_0); }, null); }
function View_NotificationCardComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_NotificationCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationCardComponent_4)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._actions.templateRef; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NotificationCardComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "button", [["class", "target"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRun.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "jc-action", [["class", "close-action"]], [[2, "__active", null]], [[null, "action"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.onClose.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ActionComponent_0, i3.RenderType_ActionComponent)), i1.ɵdid(2, 49152, null, 0, i4.ActionComponent, [], { targetAriaLabel: [0, "targetAriaLabel"] }, { action: "action" }), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "jc-cross-icon", [], null, null, null, i3.View_CrossIconComponent_0, i3.RenderType_CrossIconComponent)), i1.ɵdid(5, 49152, null, 0, i4.CrossIconComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationCardComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationCardComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("notifications.close")); _ck(_v, 2, 0, currVal_2); var currVal_3 = _co._title; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co._actions; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.runAreaLabel; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 2).isActiveCache; _ck(_v, 1, 0, currVal_1); }); }
export function View_NotificationCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-notification-card", [], [[2, "__success", null], [2, "__warning", null], [2, "__error", null]], null, null, View_NotificationCardComponent_0, RenderType_NotificationCardComponent)), i1.ɵdid(1, 49152, null, 2, i6.NotificationCardComponent, [], null, null), i1.ɵqud(603979776, 1, { _title: 0 }), i1.ɵqud(603979776, 2, { _actions: 0 })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isSuccessView; var currVal_1 = i1.ɵnov(_v, 1).isWarningView; var currVal_2 = i1.ɵnov(_v, 1).isErrorView; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var NotificationCardComponentNgFactory = i1.ɵccf("app-notification-card", i6.NotificationCardComponent, View_NotificationCardComponent_Host_0, { runAreaLabel: "runAreaLabel", viewType: "viewType" }, { onClose: "onClose", onRun: "onRun" }, ["*"]);
export { NotificationCardComponentNgFactory as NotificationCardComponentNgFactory };
